define("teelt-frontend/components/form/add-form-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-form-form'],
    customErrorService: Ember.inject.service('custom-error'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service('event-bus'),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    page: 1,
    maxPage: 2,
    init: function init() {
      this._super.apply(this, arguments);

      var allRoles = ["ROLE_MANAGER"];

      if (this.session.data.authenticated.user.company.activeOMRole) {
        allRoles.push("ROLE_CUSTOM_OFFICE_MANAGER");
      }

      if (this.session.data.authenticated.user.company.activeAdministrative) {
        allRoles.push("ROLE_ADMINISTRATIVE");
      }

      if (this.session.data.authenticated.user.company.activeMRH) {
        allRoles.push("ROLE_MRH");
      }

      var roles = this.rolecustomService.getConsultRoles();

      if (roles) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            allRoles.push("ROLE_CONSULT-" + role.id);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'allRoles', allRoles);
    },
    onPageChanged: Ember.observer('page', function () {
      if (this.page === 1) {
        this.didInsertPage1();
      }
    }),
    didInsertElement: function didInsertElement() {
      this.didInsertPage1();
      var textareas = document.querySelectorAll('.materialize-textarea');

      if (textareas && textareas.length > 0) {
        textareas.forEach(function (t) {
          M.textareaAutoResize(t);
        });
      }
    },
    didInsertPage1: function didInsertPage1() {
      var _this = this;

      setTimeout(function () {
        _this.$('.select').formSelect();
      });
    },
    setManagerVisibility: function setManagerVisibility() {
      Ember.set(this.form, 'showToManager', document.getElementById("showToManagerInput").checked);
    },
    addRemoveViewResult: function addRemoveViewResult(role) {
      var viewResultRoles = this.form.viewResultRoles;

      if (!viewResultRoles) {
        viewResultRoles = [];
      }

      var index = viewResultRoles.indexOf(role);

      if (index >= 0) {
        viewResultRoles.splice(index, 1);
      } else {
        viewResultRoles.push(role);
      }

      Ember.set(this.form, 'viewResultRoles', undefined);
      Ember.set(this.form, 'viewResultRoles', viewResultRoles);
      Ember.set(this, 'selectedRoles', undefined);
      Ember.set(this, 'selectedRoles', this.form.viewResultRoles);
    },
    addRemoveCommentRole: function addRemoveCommentRole(role) {
      var commentRoles = this.form.commentRoles;

      if (!commentRoles) {
        commentRoles = [];
      }

      var index = commentRoles.indexOf(role); //ONLY ONE COMMENT ROLE FOR THE MOMENT

      if (index >= 0) {
        //commentRoles.splice(index, 1);
        commentRoles = [];
      } else {
        //commentRoles.push(role);
        commentRoles = [role];
      }

      Ember.set(this.form, 'commentRoles', undefined);
      Ember.set(this.form, 'commentRoles', commentRoles);
      Ember.set(this, 'selectedRoles', undefined);
      Ember.set(this, 'selectedRoles', this.form.commentRoles);
    },
    actions: {
      openModalRule: function openModalRule(what) {
        Ember.set(this, 'roleForWhat', what);
        Ember.set(this, 'showRolesModal', true);

        if (what == 'comment') {
          Ember.set(this, 'selectedRoles', this.form.commentRoles);
        } else if (what == 'viewResult') {
          Ember.set(this, 'selectedRoles', this.form.viewResultRoles);
        }
      },
      addRemoveRole: function addRemoveRole(role) {
        if (this.roleForWhat == 'viewResult') {
          this.addRemoveViewResult(role);
        } else if (this.roleForWhat == 'comment') {
          this.addRemoveCommentRole(role);
        }
      },
      onClickOverlay: function onClickOverlay() {},
      selectRoles: function selectRoles() {},
      OnManagerVisibilityChanged: function OnManagerVisibilityChanged() {
        this.setManagerVisibility();
      },
      close: function close() {
        if (!this.editMode && !this.saved) {
          if (this.adminForm) {
            this.router.transitionTo('board.templates.admin-forms');
          } else {
            this.router.transitionTo('board.templates.forms');
          }
        } else {
          if (this.adminForm) {
            this.router.transitionTo('board.admin-form.form-detail', this.form.id);
          } else {
            this.router.transitionTo('board.form.form-detail', this.form.id);
          }
        }
      },
      next: function next() {
        if (this.page == 1 && !this.validateFirstPage()) return;

        if (this.page == 2) {
          if (!this.canSave && this.onFinish) this.onFinish();
          if (this.canSave) this.send('save');
          return;
        }

        Ember.set(this, 'page', this.page + 1);

        if (this.page == 2) {
          document.getElementById('g-window').classList.remove('g-window');
        }
      },
      previous: function previous() {
        if (this.page == 1) {
          this.onPrevious();
          return;
        }

        Ember.set(this, 'page', this.page - 1);

        if (this.page != 2) {
          if (!document.getElementById('g-window').classList.contains('g-window')) {
            document.getElementById('g-window').classList.add('g-window');
          }
        }
      },
      onTypeChange: function onTypeChange(type) {
        Ember.set(this.form, "type", type);

        if (type === "QUIZZ" && this.form.fields) {
          for (var i = this.form.fields.length - 1; i >= 0; i--) {
            var field = this.form.fields[i];

            if (field.type !== "short_text" && field.type !== "multiple_choice") {
              this.form.fields.removeObject(field);
            }
          }
        }
      },
      save: function save() {
        var _this2 = this;

        if (!this.validateForm()) {
          this.notify.error(this.intl.t('form.errors.missing_required_inputs'));
          return;
        }

        this.eraseFormErrors();
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = this.form.fields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var field = _step2.value;
            Ember.set(field, 'value', undefined);
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        this.form.save().then(function (form) {
          Ember.set(_this2, 'saved', true);

          if (_this2.onFinish) {
            _this2.onFinish(form);
          }
          /*
          if (this.adminForm) {
            this.router.transitionTo('board.admin-form.form-detail', form.id);
          } else {
            this.router.transitionTo('board.form.form-detail', form.id);
          }
          */

        }).catch(function (err) {
          _this2.customErrorService.handleAjaxError(err);
        });
      }
    },
    validateForm: function validateForm() {
      var _this3 = this;

      this.resetFormErrors();
      var foundErrors = false;
      this.form.fields.forEach(function (field) {
        if (!field.title) {
          Ember.set(field.inputErrors, "titleIsNull", true);
          foundErrors = true;
        }

        if (field.type === "multiple_choice") {
          if (!field.possibleResponses || field.possibleResponses.length < 1) {
            Ember.set(field.inputErrors, "globalError", _this3.intl.t("form.errors.missing_choices"));
            foundErrors = true;
          }
        }

        if (field.possibleResponses && field.possibleResponses.length > 0 && field.type !== "file") {
          field.possibleResponses.forEach(function (choice) {
            if (!choice.value) {
              Ember.set(choice.inputErrors, "labelIsNull", true);
              foundErrors = true;
            }
          });
        }

        if (field.type === "opinion_scale" || field.type === "satisfaction_rate") {
          if (!field.properties.fromValue || !field.properties.toValue) {
            Ember.set(field.inputErrors, "globalError", _this3.intl.t("form.errors.wrong_steps"));
            foundErrors = true;
          }
        }

        if (_this3.form.type === "QUIZZ") {
          if (!field.points && field.points !== 0) {
            Ember.set(field.inputErrors, "pointsIsNull", true);
            foundErrors = true;
          }
        }
      });
      return !foundErrors;
    },
    resetFormErrors: function resetFormErrors() {
      var _this4 = this;

      this.form.fields.forEach(function (field) {
        Ember.set(field, "inputErrors", {});

        if (field.type === "multiple_choice" || field.type === "short_text" && _this4.form.type === "QUIZZ") {
          field.possibleResponses.forEach(function (choice) {
            Ember.set(choice, "inputErrors", {});
          });
        }
      });
    },
    eraseFormErrors: function eraseFormErrors() {
      this.form.fields.forEach(function (field) {
        Ember.set(field, "inputErrors", undefined);

        if (field.type === "multiple_choice") {
          field.possibleResponses.forEach(function (choice) {
            Ember.set(choice, "inputErrors", undefined);
          });
        }
      });
    },
    validateFirstPage: function validateFirstPage() {
      this.resetFirstPageErrors();

      if (!this.form.title) {
        Ember.set(this, 'nameIsNull', true);
        return false;
      }

      return true;
    },
    resetFirstPageErrors: function resetFirstPageErrors() {
      Ember.set(this, 'nameIsNull', undefined);
    }
  });

  _exports.default = _default;
});