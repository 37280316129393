define("teelt-frontend/templates/board/talent/talent-detail/my-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nzd/opg2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"talents/talent-detail-actions-block\",null,[[\"talent\",\"hideTalentTask\"],[[23,[\"model\",\"talent\"]],true]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"is-authorize\",[\"ROLE_RH\",\"ROLE_MRH\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"talents/talent-referents-actions\",null,[[\"talent\"],[[23,[\"model\",\"talent\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/board/talent/talent-detail/my-actions.hbs"
    }
  });

  _exports.default = _default;
});