define("teelt-frontend/components/emails/offset-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['offset-picker'],
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    showLimitOffset: false,
    timeOutId: null,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'startOffsetUnitPossibleValues', ["DAY", "HOUR"]);
      Ember.set(this, 'endOffsetUnitPossibleValues', ["DAY", "HOUR"]);
      Ember.set(this, 'startOffsetDirectionPossibleValues', ["BEFORE", "AFTER"]);
      Ember.set(this, 'endOffsetDirectionPossibleValues', ["BEFORE", "AFTER"]);
      Ember.set(this.offset, 'startOffset', 0);
      Ember.set(this.offset, 'endOffset', 0);

      if (this.templatePeriodTasks) {
        if (this.templatePeriodTasks.id) {
          Ember.set(this.offset, 'id', this.templatePeriodTasks.id);
        }

        if (this.templatePeriodTasks.startOffset) {
          Ember.set(this.offset, 'startOffset', this.templatePeriodTasks.startOffset);
        }

        if (this.templatePeriodTasks.startOffsetUnit) {
          Ember.set(this.offset, 'startOffsetUnit', this.templatePeriodTasks.startOffsetUnit);
        }

        if (this.templatePeriodTasks.startOffsetDirection) {
          Ember.set(this.offset, 'startOffsetDirection', this.templatePeriodTasks.startOffsetDirection.toUpperCase());
        }

        if (this.templatePeriodTasks.endOffset) {
          Ember.set(this.offset, 'endOffset', this.templatePeriodTasks.endOffset);
        }

        if (this.templatePeriodTasks.endOffsetUnit) {
          Ember.set(this.offset, 'endOffsetUnit', this.templatePeriodTasks.endOffsetUnit);
        }

        if (this.templatePeriodTasks.endOffsetDirection) {
          Ember.set(this.offset, 'endOffsetDirection', this.templatePeriodTasks.endOffsetDirection.toUpperCase());
        }
      }

      if (this.period && this.period.period) {
        var directions = [];

        if (this.period.period.offset < 0) {
          directions.push('BEFORE');

          if (this.period.period.duration + this.period.period.offset > 0) {
            directions.push("AFTER");
          }
        } else {
          directions.push("AFTER");
        }

        Ember.set(this, 'startOffsetDirectionPossibleValues', directions);
        Ember.set(this, 'endOffsetDirectionPossibleValues', directions);
      }

      Ember.set(this.offset, 'mandatory', this.templatePeriodTasks.mandatory);
      Ember.set(this.offset, 'triggerInitiator', this.templatePeriodTasks.triggerInitiator);
      Ember.set(this.offset, 'notificationRecipient', this.templatePeriodTasks.notificationRecipient);
      Ember.set(this.offset, 'showLimitOffset', this.templatePeriodTasks.showLimitOffset);
      var excludeBeforeDirectionForTriggers = ["talent_created", "task_completed", "task_not_completed", "admin_forms_finished", "custom_field", "talent_added_to_template", "talent_added_to_session"];

      if (this.isOtherNotificationTask && this.templateNotification && excludeBeforeDirectionForTriggers.includes(this.templateNotification.triggerInitiator)) {
        Ember.set(this, 'startOffsetDirectionPossibleValues', ['AFTER']);
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.offset.showLimitOffset === true) {
        if (!this.offset.endOffset) {
          Ember.set(this.offset, 'endOffset', 0);
        }

        if (!this.offset.endOffsetUnit) {
          Ember.set(this.offset, 'endOffsetUnit', this.endOffsetUnitPossibleValues[0].toLowerCase());
        }

        if (!this.offset.endOffsetDirection) {
          Ember.set(this.offset, 'endOffsetDirection', this.endOffsetDirectionPossibleValues[0]);
        }
      } else {
        Ember.set(this.offset, 'endOffset', null);
        Ember.set(this.offset, 'endOffsetUnit', null);
        Ember.set(this.offset, 'endOffsetDirection', null);
      }

      if (!this.offset.startOffset) {
        Ember.set(this.offset, 'startOffset', 0);
      }

      if (!this.offset.startOffsetUnit) {
        Ember.set(this.offset, 'startOffsetUnit', this.startOffsetUnitPossibleValues[0].toLowerCase());
      }

      if (!this.offset.startOffsetDirection) {
        Ember.set(this.offset, 'startOffsetDirection', this.startOffsetDirectionPossibleValues[0]);
      }

      Ember.set(this.offset, 'localtcreate', (0, _moment.default)());
      this.$('.offset-select').formSelect();
      Ember.set(this, "templatePeriodTasks", this.offset);
      this.checkStartDate();
      this.checkEndDate();
    },
    checkStartDate: function checkStartDate() {
      if (!this.period) return;
      var value = parseInt(this.offset.startOffset);
      if (isNaN(value) || !this.offset.startOffsetDirection) return;

      if (this.offset.startOffsetUnit.toLowerCase() == 'day') {
        value = 24 * value;
      }

      var min = 24 * this.period.period.offset;
      var max = 24 * (this.period.period.offset + this.period.period.duration);

      if (this.offset.startOffsetDirection.toLowerCase() == 'before') {
        value = -1 * value;
      }

      if (value < min) {
        Ember.set(this.offset, 'startOffset', Math.abs(this.offset.startOffsetUnit.toLowerCase() == 'day' ? Math.floor(min / 24) : min));
      }

      if (value > max) {
        Ember.set(this.offset, 'startOffset', Math.abs(this.offset.startOffsetUnit.toLowerCase() == 'day' ? Math.floor(max / 24) : max));
      }
    },
    checkEndDate: function checkEndDate() {
      if (!this.period) return;
      var value = parseInt(this.offset.endOffset);
      if (isNaN(value) || !this.offset.endOffsetDirection || !this.offset.startOffsetDirection) return;

      if (this.offset.endOffsetUnit.toLowerCase() == 'day') {
        value = 24 * value;
      }

      if (this.offset.endOffsetDirection.toLowerCase() == 'before') {
        value = -1 * value;
      }

      var minValue = this.offset.startOffset;

      if (this.offset.startOffsetUnit.toLowerCase() == 'day') {
        minValue = 24 * minValue;
      }

      if (this.offset.startOffsetDirection.toLowerCase() == 'before') {
        minValue = -1 * minValue;
      }

      if (value < minValue) {
        Ember.set(this.offset, 'endOffset', Math.abs(this.offset.endOffsetUnit.toLowerCase() == 'day' ? Math.floor(minValue / 24) : minValue));
      }
    },
    actions: {
      handleChange: function handleChange() {
        Ember.set(this.templatePeriodTasks, "showLimitOffset", !this.templatePeriodTasks.showLimitOffset);

        if (this.templatePeriodTasks.showLimitOffset === false) {
          Ember.set(this.offset, 'endOffset', null);
          Ember.set(this.offset, 'endOffsetUnit', null);
          Ember.set(this.offset, 'endOffsetDirection', null);
        } else {
          var endOffsetMin = this.offset.endOffsetDirection == 'BEFORE' ? -1 * this.offset.endOffset : this.offset.endOffset;

          if (endOffsetMin < this.min) {
            Ember.set(this.offset, 'endOffset', this.min);
          }

          Ember.set(this.offset, 'endOffset', 0);
          Ember.set(this.offset, 'endOffsetUnit', "hour");
          Ember.set(this.offset, 'endOffsetDirection', this.endOffsetDirectionPossibleValues[0]);
        }

        Ember.set(this, "templatePeriodTasks", this.offset);
      },
      onStartOffsetUnitChanged: function onStartOffsetUnitChanged(value) {
        Ember.set(this.offset, "startOffsetUnit", value.toLowerCase());
        Ember.set(this, "templatePeriodTasks", this.offset);
        this.send('checkDates');
      },
      onStartOffsetDirectionChanged: function onStartOffsetDirectionChanged(value) {
        Ember.set(this.offset, "startOffsetDirection", value);
        Ember.set(this, "templatePeriodTasks", this.offset);
        this.send('checkDates');
      },
      onEndOffsetUnitChanged: function onEndOffsetUnitChanged(value) {
        Ember.set(this.offset, "endOffsetUnit", value.toLowerCase());
        Ember.set(this, "templatePeriodTasks", this.offset);
        this.send('checkDates');
      },
      onEndOffsetDirectionChanged: function onEndOffsetDirectionChanged(value) {
        Ember.set(this.offset, "endOffsetDirection", value);
        Ember.set(this, "templatePeriodTasks", this.offset);
        this.send('checkDates');
      },
      decPeriod: function decPeriod(trigger) {
        var value = parseInt(this.offset[trigger]);

        if (isNaN(value)) {
          value = 0;
        }

        if (value > 0) {
          Ember.set(this.offset, trigger, value - 1);
        }

        this.send('checkDates');
      },
      incPeriod: function incPeriod(trigger) {
        var value = parseInt(this.offset[trigger]);

        if (isNaN(value)) {
          value = 0;
        }

        Ember.set(this.offset, trigger, value + 1);
        this.send('checkDates');
      },
      checkDates: function checkDates() {
        var _this = this;

        if (this.timeOutId) {
          clearTimeout(this.timeOutId);
        }

        var timeOutId = setTimeout(function () {
          _this.checkStartDate();

          _this.checkEndDate();
        }, 2000);
        Ember.set(this, 'timeOutId', timeOutId);
      }
    }
  });

  _exports.default = _default;
});