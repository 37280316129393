define("teelt-frontend/components/task/new-tasks-list", ["exports", "teelt-frontend/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-tasks-list'],
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    userService: Ember.inject.service('user-service'),
    showNotifActions: -1,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.reorderTemplateNotifications(this.template.notifications);
      setTimeout(function () {
        if (_this.session.data.authenticated.user.company.essential) {
          var elements = document.querySelectorAll('.add-task');

          if (elements) {
            elements.forEach(function (element) {
              element.style.display = 'none';
            });
          }

          var addPeriodBtnEl = document.getElementById('add-period-btn');

          if (addPeriodBtnEl) {
            addPeriodBtnEl.style.display = 'none';
          }
        }
      }, 500);
    },
    templatePeriods: Ember.computed('template.periods', function () {
      var _this2 = this;

      var periods = this.template.periods;
      if (!periods) return;
      periods.sort(function (p1, p2) {
        return p1.period.offset < p2.period.offset ? -1 : 1;
      });
      periods.forEach(function (period) {
        _this2.reorderPeriodTasks(period.tasks);
      });
      return periods;
    }),
    groupNotifications: Ember.computed('template.notifications', 'refresh', function () {
      var notifications = this.template.notifications;
      if (!notifications) return;
      var groupedNotifications = notifications.reduce(function (groups, notification) {
        if (!groups[notification.triggerInitiator]) {
          groups[notification.triggerInitiator] = [];
        }

        groups[notification.triggerInitiator].push(notification);
        return groups;
      }, {});

      for (var group in groupedNotifications) {
        this.reorderPeriodTasks(groupedNotifications[group]);
      }

      return groupedNotifications;
    }),
    reorderPeriodTasks: function reorderPeriodTasks(tasks) {
      tasks.sort(function (t1, t2) {
        var value1 = 0;
        var value2 = 0;

        if (t1.startOffset && t1.startOffsetUnit && t1.startOffsetDirection) {
          value1 = t1.startOffset;

          if (t1.startOffsetUnit == 'day') {
            value1 = value1 * 24;
          } else if (t1.startOffsetUnit == 'month') {
            value1 = value1 * 24 * 30;
          }

          if (t1.startOffsetDirection == 'BEFORE') {
            value1 = -1 * value1;
          }
        }

        if (t2.startOffset && t2.startOffsetUnit && t2.startOffsetDirection) {
          value2 = t2.startOffset;

          if (t2.startOffsetUnit == 'day') {
            value2 = value2 * 24;
          } else if (t1.startOffsetUnit == 'month') {
            value2 = value2 * 24 * 30;
          }

          if (t2.startOffsetDirection == 'BEFORE') {
            value2 = -1 * value2;
          }
        }

        if (t1.customDate && t2.customDate) {
          return (0, _moment.default)(t1.customDate) - (0, _moment.default)(t2.customDate);
        }

        return value1 - value2;
      });
    },
    reinitEditTask: function reinitEditTask() {
      Ember.set(this, 'editTask', undefined);
      Ember.set(this, 'period', undefined);
      Ember.set(this, 'showNewTask', false);
      Ember.set(this, 'isNotificationTask', false);
      Ember.set(this, 'isOtherNotificationTask', false);
      Ember.set(this, 'step', undefined);
      Ember.set(this, 'maxStep', undefined);
      Ember.set(this, 'showPlanning', true);
      Ember.set(this, 'editFromTaskList', undefined);
      Ember.set(this, 'showEditConfirm', undefined);
    },
    reorderTemplateNotifications: function reorderTemplateNotifications(notifications) {
      var _this3 = this;

      if (!notifications) return;
      Ember.set(this.template, 'notifications', undefined);
      notifications.sort(function (a, b) {
        if (a.triggerInitiator < b.triggerInitiator) return -1;
        if (a.triggerInitiator > b.triggerInitiator) return 1;
        return 0;
      });
      setTimeout(function () {
        Ember.set(_this3.template, 'notifications', notifications);
      }, 100);
    },
    actions: {
      openNewTaskOptions: function openNewTaskOptions(periodId) {
        if (this.showTaskActions == periodId) {
          Ember.set(this, 'showTaskActions', false);
        } else {
          Ember.set(this, 'showTaskActions', periodId);
        }
      },
      closeNewTaskActions: function closeNewTaskActions() {
        $(document).off('click');
        Ember.set(this, 'showTaskActions', false);
      },
      openPeriodList: function openPeriodList(zone) {
        var _this4 = this;

        var templateType = this.template.type;
        var triggerDate = templateType + 'Date';
        this.store.query('period', {
          triggerDate: triggerDate
        }).then(function (periods) {
          if (periods.length == 0) {
            _this4.notify.error(_this4.intl.t('task.period.no_period', {
              settings: _this4.intl.t('word.settings'),
              templates: _this4.intl.t('word.templates')
            }), {
              closeAfter: 5000
            });

            return;
          }

          Ember.set(_this4, zone + '_periods', periods);
          Ember.set(_this4, 'selectedPeriodId', periods.get('firstObject').id);
          setTimeout(function () {
            _this4.$('#' + zone + '-period-select').formSelect();
          });
        });
      },
      onPeriodChanged: function onPeriodChanged(value) {
        Ember.set(this, 'selectedPeriodId', value);
      },
      addPeriod: function addPeriod(zone) {
        var _this5 = this;

        var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.selectedPeriodId;
        this.ajax.requestPromise(path, 'POST').then(function (period) {
          Ember.set(_this5, zone + '_periods', null);
          var periods = _this5.template.periods;

          if (!periods) {
            periods = [];
          }

          periods.push(period);
          Ember.set(_this5.template, 'periods', undefined);
          Ember.set(_this5.template, 'periods', periods);
        });
      },
      removePeriod: function removePeriod(result) {
        var _this6 = this;

        if (result) {
          var periodToDeleteId = this.periodToDelete.period.id;
          var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.periodToDelete.period.id;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            var periods = [];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = _this6.template.periods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var p = _step.value;
                if (p.period.id == periodToDeleteId) continue;
                periods.push(p);
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            Ember.set(_this6.template, 'periods', undefined);
            Ember.set(_this6.template, 'periods', periods);
            Ember.set(_this6, 'periodToDelete', undefined);
          });
        } else {
          Ember.set(this, 'periodToDelete', undefined);
        }
      },
      addTask: function addTask(period) {
        this.reinitEditTask();
        Ember.set(this, 'showTaskActions', false);
        Ember.set(this, 'editTask', undefined);
        Ember.set(this, 'period', period);
        Ember.set(this, 'isNotificationTask', false);
        Ember.set(this, 'isOtherNotificationTask', false);
        Ember.set(this, 'resetAll', true);
        Ember.set(this, 'showNewTask', true);
      },
      addNotificationTask: function addNotificationTask(period) {
        this.reinitEditTask();
        Ember.set(this, 'showTaskActions', false);
        Ember.set(this, 'editTask', undefined);
        Ember.set(this, 'period', period);
        Ember.set(this, 'isNotificationTask', true);
        Ember.set(this, 'isOtherNotificationTask', false);
        Ember.set(this, 'resetAll', true);
        Ember.set(this, 'showNewTask', true);
      },
      addOtherNotif: function addOtherNotif() {
        this.reinitEditTask();
        Ember.set(this, 'showTaskActions', false);
        Ember.set(this, 'editTask', undefined);
        Ember.set(this, 'template', this.template);
        Ember.set(this, 'isNotificationTask', false);
        Ember.set(this, 'isOtherNotificationTask', true);
        Ember.set(this, 'period', undefined);
        Ember.set(this, 'resetAll', true);
        Ember.set(this, 'showNewTask', true);
      },
      editOtherNotif: function editOtherNotif(templateNotification) {
        this.reinitEditTask();
        var excludeBeforeDirectionForTriggers = ["talent_created", "task_completed", "task_not_completed", "admin_forms_finished", "custom_field"];

        if (templateNotification && excludeBeforeDirectionForTriggers.includes(templateNotification.triggerInitiator)) {
          Ember.set(templateNotification, 'startOffsetDirection', 'AFTER');
        }

        Ember.set(this, 'showTaskActions', false);
        Ember.set(this, 'editTask', templateNotification);
        Ember.set(this, 'template', this.template);
        Ember.set(this, 'isNotificationTask', false);
        Ember.set(this, 'isOtherNotificationTask', true);
        Ember.set(this, 'resetAll', true);
        Ember.set(this, 'showNewTask', true);
      },
      onTaskAdded: function onTaskAdded(task, templatePeriodTask) {
        var _this7 = this;

        var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.period.id + "/tasks/" + task.id;
        var method = 'POST';

        if (templatePeriodTask.id) {
          path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.id + '/tasks/' + templatePeriodTask.id;
          method = 'PATCH';
        }

        this.ajax.requestWithBodyPromise(path, method, 'application/json', JSON.stringify(templatePeriodTask)).then(function (result) {
          var periodTasks = _this7.period.tasks;
          var taskToUpdate = task;

          if (method == 'PATCH') {
            var _iteratorNormalCompletion2 = true;
            var _didIteratorError2 = false;
            var _iteratorError2 = undefined;

            try {
              for (var _iterator2 = periodTasks[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                var periodTask = _step2.value;

                if (periodTask.id == task.id) {
                  taskToUpdate = periodTask;
                  break;
                }
              }
            } catch (err) {
              _didIteratorError2 = true;
              _iteratorError2 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                  _iterator2.return();
                }
              } finally {
                if (_didIteratorError2) {
                  throw _iteratorError2;
                }
              }
            }
          } else {
            Ember.set(taskToUpdate, 'templatePeriodTaskId', Number(result));
          }

          Ember.set(taskToUpdate, 'notification', templatePeriodTask.notification);
          Ember.set(taskToUpdate, 'periodMandatory', templatePeriodTask.mandatory);
          Ember.set(taskToUpdate, 'periodNotificationRecipient', templatePeriodTask.notificationRecipient);
          Ember.set(taskToUpdate, 'startOffset', templatePeriodTask.startOffset);
          Ember.set(taskToUpdate, 'startOffsetUnit', templatePeriodTask.startOffsetUnit);
          Ember.set(taskToUpdate, 'startOffsetDirection', templatePeriodTask.startOffsetDirection);
          Ember.set(taskToUpdate, 'editable', templatePeriodTask.editable);

          if (method == 'POST') {
            periodTasks.push(taskToUpdate);
          }

          _this7.reorderPeriodTasks(periodTasks);

          Ember.set(_this7.period, 'tasks', undefined);
          Ember.set(_this7.period, 'tasks', periodTasks);

          _this7.addContentToTemplate(task);
        }).catch(function (e) {
          if (e.responseJSON, e.responseJSON.code && e.responseJSON.code == 'ALREADY_EXISTS') {
            _this7.notify.error(_this7.intl.t('template.errors.task_alredy_exists'));
          }
        });
      },
      onOtherTaskAdded: function onOtherTaskAdded(template) {
        if (!this.template.notifications) {
          Ember.set(this.template, 'notifications', []);
        }

        Ember.set(this.template, 'notifications', undefined);
        Ember.set(this.template, 'notifications', template.notifications);
        this.reorderTemplateNotifications(template.notifications);
      },
      onOtherTaskUpdated: function onOtherTaskUpdated() {
        Ember.set(this, 'refresh', new Date());
      },
      closeActions: function closeActions() {
        var _this8 = this;

        setTimeout(function () {
          Ember.set(_this8, 'showNotifActions', false);
        });
      },
      openShowTasks: function openShowTasks(taskId) {
        Ember.set(this, 'showActions', this.showActions == taskId ? false : taskId);
      },
      deleteTask: function deleteTask(task, period) {
        Ember.set(this, 'taskToDelete', task);
        Ember.set(this, 'period', period);
        Ember.set(this, 'showActions', undefined);
      },
      editTask: function editTask(task, period, step, maxStep) {
        var showPlanning = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
        var editFromTaskList = arguments.length > 5 ? arguments[5] : undefined;
        var showEditConfirm = arguments.length > 6 ? arguments[6] : undefined;
        this.reinitEditTask();
        Ember.set(this, 'editTask', task);
        Ember.set(this, 'period', period);
        Ember.set(this, 'showNewTask', !showEditConfirm);
        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'isNotificationTask', task.notification != undefined);
        Ember.set(this, 'isOtherNotificationTask', false);
        Ember.set(this, 'step', step);
        Ember.set(this, 'maxStep', maxStep);
        Ember.set(this, 'showPlanning', showPlanning);
        Ember.set(this, 'editFromTaskList', editFromTaskList);
        Ember.set(this, 'showEditConfirm', showEditConfirm);
      },
      editTaskConfirm: function editTaskConfirm(result) {
        if (result) {
          Ember.set(this, 'showNewTask', true);
        } else {
          this.reinitEditTask();
        }
      },
      editTaskNotification: function editTaskNotification(task, period) {
        this.reinitEditTask();
        Ember.set(this, 'editTask', task);
        Ember.set(this, 'period', period);
        Ember.set(this, 'showNewTask', true);
        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'isNotificationTask', true);
        Ember.set(this, 'isOtherNotificationTask', false);
        Ember.set(this, 'step', 5);
        Ember.set(this, 'maxStep', 7);
      },
      editSend: function editSend(task, period) {
        this.send('editTask', task, period, undefined, 5);
        Ember.set(this, 'isNotificationTask', true);
      },
      editModule: function editModule(task) {
        var _this9 = this;

        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'updateTask', task);
        Ember.set(this, 'showUpdateModule', false);
        Ember.set(this, 'showConfirm', true);

        switch (task.type) {
          case 'form':
            this.store.findRecord('form', task.form.id, {
              reload: true
            }).then(function (form) {
              Ember.set(_this9, 'updateModuleType', 'form');
              Ember.set(_this9, 'updateModule', form);
            });
            break;

          case 'administrative':
            this.store.findRecord('form', task.form.id, {
              reload: true
            }).then(function (form) {
              Ember.set(_this9, 'updateModuleType', 'administrative');
              Ember.set(_this9, 'updateModule', form);
            });
            break;

          case 'content':
            this.store.findRecord('content', task.content.id, {
              reload: true
            }).then(function (content) {
              Ember.set(_this9, 'updateModuleType', 'content');
              Ember.set(_this9, 'updateModule', content);
            });
            break;

          case 'sign':
            this.store.findRecord('signcontent', task.signContent.id, {
              reload: true
            }).then(function (sign) {
              Ember.set(_this9, 'updateModuleType', 'sign');
              Ember.set(_this9, 'updateModule', sign);
            });
            break;

          case 'meetPeople':
            this.store.findRecord('meetpeople', task.meetPeople.id, {
              reload: true
            }).then(function (meetPeople) {
              Ember.set(_this9, 'updateModuleType', 'meetPeople');
              Ember.set(_this9, 'updateModule', meetPeople);
            });
            break;

          case 'exploreOffice':
            this.store.findRecord('exploreoffice', task.exploreOffice.id, {
              reload: true
            }).then(function (exploreOffice) {
              Ember.set(_this9, 'updateModuleType', 'exploreOffice');
              Ember.set(_this9, 'updateModule', exploreOffice);
            });
            break;
        }
      },
      onFinishModule: function onFinishModule(module) {
        switch (this.updateModuleType) {
          case 'form':
          case 'administrative':
            Ember.set(this.updateTask, 'form', module.toJSON({
              includeId: true
            }));
            break;

          case 'content':
            Ember.set(this.updateTask, 'content', module.toJSON({
              includeId: true
            }));
            break;

          case 'sign':
            Ember.set(this.updateTask, 'signContent', module.toJSON({
              includeId: true
            }));
            break;

          case 'notification':
            Ember.set(this.updateTask, 'notification', module.toJSON({
              includeId: true
            }));
            break;

          case 'meetPeople':
            Ember.set(this.updateTask, 'meetPeople', module);
            break;

          case 'exploreOffice':
            Ember.set(this.updateTask, 'exploreOffice', module);
            break;
        }

        this.send('previousModule');
      },
      previousModule: function previousModule() {
        Ember.set(this, 'updateModule', undefined);
        Ember.set(this, 'updateTask', undefined);
        Ember.set(this, 'showUpdateModule', false);
      },
      editNotification: function editNotification(task) {
        var _this10 = this;

        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'showUpdateModule', false);
        Ember.set(this, 'showConfirm', true);
        Ember.set(this, 'updateTask', task);

        if (task.notification) {
          this.store.findRecord('notification', task.notification.id, {
            reload: true
          }).then(function (notif) {
            Ember.set(_this10, 'updateModuleType', 'notification');
            Ember.set(_this10, 'updateModule', notif);
          });
        }
      },
      editNotificationVersion: function editNotificationVersion(task) {
        var _this11 = this;

        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'showNotifActions', undefined);
        Ember.set(this, 'showEditNotifVersionConfirm', true);

        if (task.notification) {
          this.store.findRecord('notification', task.notification.id).then(function (notification) {
            Ember.set(_this11, 'updateNotifVersion', notification);
          });
        }
      },
      removeTaskFromPeriod: function removeTaskFromPeriod(result) {
        var _this12 = this;

        if (result) {
          var taskId = this.taskToDelete.id;
          var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.period.id + "/tasks/" + taskId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            var periodTasks = _this12.period.tasks;

            for (var i = 0; i < periodTasks.length; i++) {
              if (periodTasks[i].id == taskId) {
                periodTasks.splice(i, 1);
                break;
              }
            }

            Ember.set(_this12.period, 'tasks', undefined);
            Ember.set(_this12.period, 'tasks', periodTasks);
            Ember.set(_this12, 'taskToDelete', undefined);
          });
        } else {
          Ember.set(this, 'taskToDelete', undefined);
        }
      },
      openShowNotifOption: function openShowNotifOption(notifId) {
        Ember.set(this, 'showNotifActions', this.showNotifActions == notifId ? false : notifId);
      },
      deleteNotification: function deleteNotification(notification) {
        Ember.set(this, 'notifToDelete', notification);
        Ember.set(this, 'showNotifActions', undefined);
      },
      removeNotification: function removeNotification(result) {
        var _this13 = this;

        if (result) {
          var notifId = this.notifToDelete.id;
          var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/notifications/' + notifId;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            var templateNotifs = _this13.template.notifications;

            for (var i = 0; i < templateNotifs.length; i++) {
              if (templateNotifs[i].id == notifId) {
                templateNotifs.splice(i, 1);
                break;
              }
            }

            Ember.set(_this13.template, 'notifications', undefined);
            Ember.set(_this13.template, 'notifications', templateNotifs);
            Ember.set(_this13, 'notifToDelete', undefined);
          });
        } else {
          Ember.set(this, 'notifToDelete', undefined);
        }
      },
      copyContentUrlToClipboard: function copyContentUrlToClipboard(task) {
        var subdomain = _environment.default.APP.PROTOCOL + window.location.host;
        var url = "";

        if (task.type === 'administrative') {
          url = subdomain + '/talent/integration/administratives?formId=' + task.form.id;
        } else if (task.type === 'form') {
          url = subdomain + '/talent/integration/forms?formId=' + task.form.id;
        } else {
          url = subdomain + '/talent/integration/tasks?contentTaskId=' + task.id;
        }

        if (!subdomain || !url) {
          this.notify.error("An error occurred while copying the link", {
            closeAfter: 5000
          });
          return;
        }

        var tempElem = document.createElement("input");
        document.body.appendChild(tempElem);
        tempElem.setAttribute('value', url);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        this.notify.info(this.intl.t('module.module_link_copied_to_clipboard'), {
          closeAfter: 5000
        });
      },
      showMeetPeopleGallery: function showMeetPeopleGallery(task) {
        Ember.set(this, "showMeetPeopleGallery", true);
        Ember.set(this, "meetPeopleId", task.meetPeople.id);
        Ember.set(this, "showGalleryModal", true);
      },
      toggleEditable: function toggleEditable(element, period) {
        var _this14 = this;

        if (!this.userService.isRH(this.session.data.authenticated.user) || !this.template.model) return;
        var path = _environment.default.APP.API_HOST + "/templates/" + this.template.id;

        if (period) {
          path += '/periods/' + period.period.id + "/tasks/" + element.id + "/toggle-editable";
        } else {
          path += '/notifications/' + element.id + "/toggle-editable";
        }

        this.ajax.requestPromise(path, 'PATCH').then(function (response) {
          if (period) {
            var periodTasks = _this14.template.periods.find(function (p) {
              return p.period.id == period.period.id;
            }).tasks;

            for (var i = 0; i < periodTasks.length; i++) {
              if (periodTasks[i].id == element.id) {
                Ember.set(periodTasks[i], 'editable', response.editable);
                break;
              }
            } // update only the concerned template period task in the this.template


            var templatePeriods = _this14.template.periods;

            for (var i = 0; i < templatePeriods.length; i++) {
              if (templatePeriods[i].period.id == period.period.id) {
                Ember.set(templatePeriods[i], 'tasks', periodTasks);
                break;
              }
            }

            Ember.set(_this14.template, 'periods', undefined);
            Ember.set(_this14.template, 'periods', templatePeriods);
          } else {
            var templateNotifs = _this14.template.notifications;

            for (var i = 0; i < templateNotifs.length; i++) {
              if (templateNotifs[i].id == element.id) {
                Ember.set(templateNotifs[i], 'editable', response.editable);
                break;
              }
            }

            Ember.set(_this14.template, 'notifications', undefined);
            Ember.set(_this14.template, 'notifications', templateNotifs);
          }

          Ember.set(_this14, 'showActions', undefined);
        });
      },
      showModule: function showModule(task, isNotif) {
        var _this15 = this;

        if (isNotif) {
          Ember.set(this, "previewType", "notification");
          Ember.set(this, "preview", task.notification);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }

        if (task.meetPeople) {
          Ember.set(this, "previewType", "meetPeople");
          Ember.set(this, "preview", task.meetPeople);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }

        if (task.exploreOffice) {
          Ember.set(this, 'previewType', 'exploreOffice');
          Ember.set(this, 'preview', task.exploreOffice);
          Ember.set(this, 'showPreviewDialog', true);
          return;
        }

        Ember.set(this, "previewType", task.type);

        if (task.form) {
          this.store.findRecord("form", task.form.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this15, "preview", result);
            Ember.set(_this15, "showPreviewDialog", true);
          });
          return;
        }

        if (task.signContent) {
          Ember.set(this, "preview", task.signContent);
          Ember.set(this, "showPreviewDialog", true);
          return;
        }

        if (task.content) {
          this.store.findRecord("content", task.content.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this15, "preview", result);

            if (result.type === "textcard" || result.type === "video" || result.type === "slideshow") {
              Ember.set(_this15, "showContentDetailPopup", true);
            } else if (result.type === "filecard") {
              _this15.openFileCard(result);
            }
          });
          return;
        }

        if (task.notification) {
          this.store.findRecord("notification", task.notification.id, {
            reload: true
          }).then(function (result) {
            Ember.set(_this15, "preview", result);
            Ember.set(_this15, "showPreviewDialog", true);
          });
        }
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'showPreviewDialog', false);
      },
      hideTask: function hideTask(task, period) {
        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'taskToHide', task);
        Ember.set(this, 'notificationToHide', undefined);
        Ember.set(this, 'period', period);
        Ember.set(this, 'showHidingAudiencesModal', true);
      },
      hideNotification: function hideNotification(notification) {
        Ember.set(this, 'showNotifActions', undefined);
        Ember.set(this, 'taskToHide', undefined);
        Ember.set(this, 'notificationToHide', notification);
        Ember.set(this, 'showHidingAudiencesModal', true);
      },
      taskHidingChanged: function taskHidingChanged(audiences) {
        var _this16 = this;

        Ember.set(this.taskToHide, 'hidingAudiences', undefined);
        setTimeout(function () {
          return Ember.set(_this16.taskToHide, 'hidingAudiences', audiences);
        });
        var templateTask = {
          id: this.taskToHide.templatePeriodTaskId,
          hidingAudiences: audiences
        };
        var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/periods/' + this.period.id + "/tasks/" + this.taskToHide.templatePeriodTaskId + "/hiding";
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(templateTask)).then(function () {
          _this16.notify.info(_this16.intl.t("template.tasks.hide.success"));
        }).catch(function () {
          _this16.notify.error(_this16.intl.t("custom_errors.error_undefined"));
        });
      },
      notificationHidingChanged: function notificationHidingChanged(audiences) {
        var _this17 = this;

        Ember.set(this.notificationToHide, 'hidingAudiences', undefined);
        setTimeout(function () {
          return Ember.set(_this17.notificationToHide, 'hidingAudiences', audiences);
        });
        var templateNotification = {
          id: this.notificationToHide.id,
          hidingAudiences: audiences
        };
        var path = _environment.default.APP.API_HOST + '/templates/' + this.template.id + '/notifications/' + this.notificationToHide.id + "/hiding";
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(templateNotification)).then(function () {
          _this17.notify.info(_this17.intl.t("template.tasks.hide.success"));
        }).catch(function () {
          _this17.notify.error(_this17.intl.t("custom_errors.error_undefined"));
        });
      }
    },
    addContentToTemplate: function addContentToTemplate(task) {
      var _this18 = this;

      if (task.content) {
        this.store.query('content', {
          filter: "template" + this.template.id
        }).then(function (contents) {
          //check if content already exists in template contents
          var contentExists = false;
          contents.forEach(function (content) {
            if (content.genericContent.id === task.content.id) {
              contentExists = true;
            }
          });

          if (!contentExists) {
            var _contents = [];
            Ember.set(task.content, 'copySection', 'template' + _this18.template.id);
            Ember.set(task.content, 'createCopy', true);

            _contents.push(task.content);

            var path = _environment.default.APP.API_HOST + '/contents/multi';

            _this18.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(_contents)).then(function () {
              _this18.notify.info(_this18.intl.t('content.info.generic_content_added_to_template'), {
                closeAfter: 5000
              });
            });
          }
        });
      }
    },
    openFileCard: function openFileCard(content) {
      var _this19 = this;

      if (!content.fullValue || content.fullValue.trim() == '') return; // WARNING : Always open tab before fetch temporary url because Firefox authorize opened tab by user click only and not in promise resolve

      Ember.set(this, "showDownloadingModal", true);
      this.fileUploadService.getObjectUrl(content.fullValue).then(function (data) {
        var url = data.url;

        if (!content.toDownload) {
          url += "&inline";
        }

        window.open(url, 'newtab');
        Ember.set(_this19, "showDownloadingModal", false);
      }).catch(function (err) {
        _this19.customErrorService.handleAjaxError(err);
      });
    }
  });

  _exports.default = _default;
});