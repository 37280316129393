define("teelt-frontend/components/task/tasks-list", ["exports", "teelt-frontend/config/environment", "teelt-frontend/helpers/task/count-task-for-period-assign"], function (_exports, _environment, _countTaskForPeriodAssign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tasks-list'],
    staticlikeData: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    store: Ember.inject.service(),
    filter: 'all',
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'companyHasOfficeManager', this.session.data.authenticated.user.company.activeOMRole);
      var possiblesAssign = [];

      if (this.forceAssign) {
        possiblesAssign.push(this.forceAssign);
      } else {
        var user = this.session.data.authenticated.user;
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = user.roles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var role = _step.value;
            var roleToPush = this.getRole(role);

            if (roleToPush) {
              possiblesAssign.push(roleToPush);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'possiblesAssign', possiblesAssign);
      var talentTemplate = this.talent.selectedTemplate;

      if (this.templateId) {
        if (this.talent.template.id == Number(this.templateId)) {
          talentTemplate = this.talent.template;
        }

        if (this.talent.template.subTemplates) {
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = this.talent.template.subTemplates[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var subTemplate = _step2.value;

              if (subTemplate.template.id == Number(this.templateId)) {
                talentTemplate = subTemplate.template;
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        }
      }

      Ember.set(this, 'talentTemplate', talentTemplate);
    },
    calculateShowThirdPeriod: function calculateShowThirdPeriod(template, possiblesAssign) {
      if (template && template.periods) {
        for (var i = 0; i < template.periods.length; i++) {
          var tPeriod = template.periods[i];
          tPeriod.countTask = (0, _countTaskForPeriodAssign.countTask)([template, tPeriod.period.id, possiblesAssign, this.talent, this.filter]);

          if (i <= 1 && tPeriod.countTask) {
            break;
          }

          if (i > 1 && tPeriod.countTask) {
            return true;
          }
        }
      }
    },
    getRole: function getRole(role) {
      if (role == 'ROLE_RH') {
        return 'rh';
      }

      if (role == "ROLE_ADMINISTRATIVE") {
        return 'administrative';
      }

      if (role == "ROLE_MRH") {
        return 'adminMRH';
      }

      if (!this.talent) {
        return;
      }

      if (role == "ROLE_CUSTOM_OFFICE_MANAGER") {
        if (!this.talent.officeManagers) {
          return false;
        }

        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = this.talent.officeManagers[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var officeManager = _step3.value;

            if (officeManager.id == this.session.data.authenticated.user.id) {
              return 'officeManager';
            }
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      if (role == "ROLE_MANAGER") {
        if (!this.talent.manager) {
          return;
        }

        if (this.talent.manager.id != this.session.data.authenticated.user.id) {
          return;
        }

        return 'manager';
      }

      if (role.indexOf('ROLE_CONSULT-') == 0) {
        return role;
      }

      return false;
    },
    actions: {
      selectFilter: function selectFilter(filter) {
        Ember.set(this, 'filter', filter);
        Ember.set(this, 'showPossibleFilters', false);
      },
      validateUnvalidate: function validateUnvalidate(templateId, periodId, task) {
        var _this = this;

        if (task.form && task.form.id && (["rh", "manager", "officeManager", "administrative", "adminMRH"].includes(task.assign) || task.assign.startsWith('ROLE_CONSULT'))) {
          if (task.form.type == "ADMINISTRATIVE") {
            this.router.transitionTo("board.talent.talent-detail.administrative", this.talent.id).then(function (route) {
              Ember.set(route.currentModel.talent, 'selectedTemplateId', Number(templateId));

              _this.eventBus.trigger('REFRESH_ROUTE');

              route.openForm(task.form.id, templateId);
            });
          } else {
            this.router.transitionTo("board.talent.talent-detail.forms", this.talent.id).then(function (route) {
              Ember.set(route.currentModel.talent, 'selectedTemplateId', Number(templateId));

              _this.eventBus.trigger('REFRESH_ROUTE');

              route.openForm(task.form.id, templateId);
            });
          }

          this.eventBus.trigger('VALIDATE_TASK');
          return;
        }

        if (task.meetPeople && task.meetPeople.id) {
          this.router.transitionTo("board.talent.talent-detail.ihm", this.talent.id).then(function (route) {
            Ember.set(route.currentModel.talent, 'selectedTemplateId', Number(templateId));

            _this.eventBus.trigger('REFRESH_ROUTE');

            route.openMeetPeople(task.meetPeople.id);
          });
          this.eventBus.trigger('VALIDATE_TASK');
          return;
        }

        if (!this.canValidate) return;
        var path = _environment.default.APP.API_HOST + "/templates/" + templateId + "/periods/" + periodId + "/tasks/" + task.id + "/validate";
        var completed = false;
        var taskEl = document.getElementById('talent-task-' + task.id);

        if (taskEl) {
          var doneIconEl = taskEl.querySelector('.done-icon');
          if (doneIconEl && doneIconEl.classList.contains('completed')) completed = true;
        }

        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify({
          userId: this.talent.id,
          validate: !completed
        })).then(function () {
          if (completed) {
            if (_this.talent.taskValidations && _this.talent.taskValidations.length) {
              var taskValidations = _this.talent.taskValidations;

              for (var i = 0; i < taskValidations.length; i++) {
                if (taskValidations[i].id == task.id) {
                  taskValidations.splice(i, 1);
                  break;
                }
              }

              Ember.set(_this.talent, 'taskValidations', taskValidations);
              Ember.set(task, 'refresh', new Date());
            }
          } else {
            var _taskValidations = _this.talent.taskValidations;

            if (!_taskValidations) {
              _taskValidations = [];
            }

            _taskValidations.push(task);

            Ember.set(_this.talent, 'taskValidations', _taskValidations);
            Ember.set(task, 'refresh', new Date());
          }
        }).finally(function () {
          var taskEl = document.getElementById('talent-task-' + task.id);

          if (taskEl) {
            var startOffsetEl = taskEl.querySelector('.startOffset');

            var _doneIconEl = taskEl.querySelector('.done-icon');

            if (completed) {
              if (taskEl) taskEl.classList.remove('completed', 'background-primary-color', 'border-primary-color');
              if (startOffsetEl) startOffsetEl.classList.remove('foreground-primary-color');
              if (_doneIconEl) _doneIconEl.classList.remove('completed');
            } else {
              if (taskEl) taskEl.classList.add('completed', 'background-primary-color', 'border-primary-color');
              if (startOffsetEl) startOffsetEl.classList.add('foreground-primary-color');
              if (_doneIconEl) _doneIconEl.classList.add('completed');
            }
          }

          _this.eventBus.trigger('VALIDATE_TASK');
        });
      },
      startSignTransaction: function startSignTransaction(task, force, template) {
        var _this2 = this;

        Ember.set(this, 'showActions', undefined);
        Ember.set(this, 'blockTransaction', true);
        var path = _environment.default.APP.API_HOST + "/signcontents/tasks/" + task.id + "/users/" + this.talent.id;

        if (force) {
          path += "?force=true";
        }

        this.ajax.requestWithBodyPromise(path, 'POST').then(function () {
          _this2.notify.info(_this2.intl.t("document_sign.transaction_started"));

          path = _environment.default.APP.API_HOST + "/users/" + _this2.talent.id + "?include=fullTemplate";
          Ember.set(_this2.talent, 'template', undefined);
          Ember.set(_this2.talent, 'userSignContents', undefined);

          _this2.ajax.requestPromise(path).then(function (talent) {
            Ember.set(_this2.talent, 'userSignContents', talent.userSignContents);
            Ember.set(_this2.talent, 'template', talent.template);
            Ember.set(_this2, 'blockTransaction', false);
            Ember.set(_this2, 'signTransactionSendUrlConfirm', task);
          });
        });
      },
      onConfirmSendSignUrls: function onConfirmSendSignUrls() {
        var transactionTask = this.signTransactionSendUrlConfirm;
        this.send('sendLinkToSign', transactionTask);
        Ember.set(this, 'signTransactionSendUrlConfirm', undefined);
      },
      sendLinkToSign: function sendLinkToSign(task) {
        var _this3 = this;

        Ember.set(this, 'showActions', undefined);
        var path = _environment.default.APP.API_HOST + "/signcontents/tasks/" + task.id + "/users/" + this.talent.id + "/send";
        this.ajax.requestWithBodyPromise(path, 'POST').then(function () {
          _this3.notify.info(_this3.intl.t('document_sign.links_sent'));
        });
      },
      openTaskActions: function openTaskActions(task) {
        if (this.showActions) {
          Ember.set(this, 'showActions', undefined);
        } else {
          Ember.set(this, 'showActions', task.id);
        }
      },
      getSignedDocument: function getSignedDocument(url, attestation) {
        if (url) {
          var path = _environment.default.APP.API_HOST + "/signcontents/signedDocument?url=" + url;
          this.ajax.requestPromise(path, "GET", null, {
            responseType: 'text'
          }).then(function (data) {
            var binaryString = atob(data);
            var binaryData = new Uint8Array(binaryString.length);

            for (var i = 0; i < binaryString.length; i++) {
              binaryData[i] = binaryString.charCodeAt(i);
            }

            var blob = new Blob([binaryData], {
              type: 'application/pdf'
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            var pattern = /\/documents\/([^/]+)\/download/;
            link.download = pattern.exec(url) ? pattern.exec(url)[1] + ".pdf" : "signature.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }

        if (attestation) {
          var _path = _environment.default.APP.API_HOST + "/signcontents/signedDocument?url=" + attestation;

          this.ajax.requestPromise(_path, "GET", null, {
            responseType: 'text'
          }).then(function (data) {
            var binaryString = atob(data);
            var binaryData = new Uint8Array(binaryString.length);

            for (var i = 0; i < binaryString.length; i++) {
              binaryData[i] = binaryString.charCodeAt(i);
            }

            var blob = new Blob([binaryData], {
              type: 'application/pdf'
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "attestation.pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      },
      openUploadCustomFieldFile: function openUploadCustomFieldFile(id) {
        var editLink = document.querySelector("#talent-detail .row > .talent-informations-container:nth-child(5) > .information-header > div > span");

        if (editLink) {
          editLink.click();
          Ember.run.later(function () {
            var fieldEl = document.querySelector("#edit-input-" + id + " .clickable");

            if (fieldEl) {
              fieldEl.click();
            }
          }, 100);
        }
      }
    }
  });

  _exports.default = _default;
});