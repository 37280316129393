define("teelt-frontend/components/task/task-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    completed: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.task) {
        var taskEl = document.getElementById('talent-task-' + this.task.id);

        if (taskEl) {
          var doneIconEl = taskEl.querySelector('.done-icon');
          if (doneIconEl && doneIconEl.classList.contains('completed')) this.set('completed', true);
        }
      }
    },
    actions: {
      onClickOverlay: function onClickOverlay() {
        this.set('showTaskDetail', false);
      },
      validate: function validate() {
        if (this.onValidate) this.onValidate();
        this.set('showTaskDetail', false);
      }
    }
  });

  _exports.default = _default;
});