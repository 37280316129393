define("teelt-frontend/components/form/form-preview", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['form-preview'],
    classNameBindings: ['responseMode'],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    rolecustomService: Ember.inject.service('rolecustom-service'),
    openedResponses: [0],
    allowDocumentValidation: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.form.type === "QUIZZ" && this.form.showFinalScore) {
        this.computeMaxScore();
      }

      var currentResponseNumber = 1;

      if (this.form.fields) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.form.fields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var field = _step.value;

            if (field.value && field.value.length > 0) {
              var fieldValue = '' + field.value;
              currentResponseNumber = Math.max(currentResponseNumber, fieldValue.split('||').length);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      Ember.set(this, 'currentResponseNumber', currentResponseNumber);
      this.cleanupFieldResponses();

      if (this.session.data.authenticated.user.company.activeDocumentValidation) {
        Ember.set(this, 'allowDocumentValidation', true);
      }
    },
    cleanupFieldResponses: function cleanupFieldResponses() {
      if (!this.form && !this.form.fields) return;
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.form.fields[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var field = _step2.value;

          while (field.value && typeof field.value === "string" && field.value.indexOf('\\\\') >= 0) {
            Ember.set(field, 'value', field.value.replaceAll('\\\\', '\\'));
          }

          while (field.value && typeof field.value === "string" && field.value.indexOf('\\"') >= 0) {
            Ember.set(field, 'value', field.value.replaceAll('\\"', '"'));
          }

          while (field.value && typeof field.value === "string" && field.value.startsWith("\"") && field.value.endsWith("\"")) {
            Ember.set(field, 'value', field.value.substring(1, field.value.length - 1));
          }

          while (field.value && typeof field.value === "string" && field.value.startsWith("\\\"") && field.value.endsWith("\\\"")) {
            Ember.set(field, 'value', field.value.substring(2, field.value.length - 2));
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    },
    computeMaxScore: function computeMaxScore() {
      if (!this.form.fields) return;
      var maxScore = 0;
      this.form.fields.forEach(function (f) {
        if (f.points) {
          maxScore += f.points;
        }
      });
      Ember.set(this, 'maxScore', maxScore);
    },
    reformatAllField: function reformatAllField() {
      var maxResponses = 0;
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.form.fields[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var field = _step3.value;
          var fieldValue = void 0;

          if (field.type === 'multiple_choice' && field.properties && field.properties.allow_multiple_selection) {
            try {
              fieldValue = JSON.stringify(field.value);
            } catch (err) {
              fieldValue = field.value;
            }
          }

          if (!fieldValue) {
            fieldValue = '' + (field.value ? field.value : '');
          }

          var tmp = Array.isArray(fieldValue) ? fieldValue : fieldValue.split('||');
          var i = tmp.length - 1;

          while (i >= 0) {
            var value = tmp[i];

            try {
              value = JSON.parse(tmp[i]);
            } catch (err) {}

            if (Array.isArray(value) && value.length > 0) {
              break;
            }

            value = '' + value;

            if (value != undefined && value.length > 0) {
              break;
            }

            tmp.pop();
            i--;
          }

          maxResponses = Math.max(maxResponses, tmp.length);
          Ember.set(field, 'value', tmp.join('||'));
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = this.form.fields[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var _field = _step4.value;

          var _fieldValue = '' + (_field.value ? _field.value : '');

          var _tmp = _fieldValue.split('||');

          var _i = _tmp.length;

          while (_i < maxResponses) {
            _tmp.push('');

            _i++;
          }

          Ember.set(_field, 'value', _tmp.join('||'));
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }
    },
    validateAllFields: function validateAllFields() {
      var isOK = true;
      if (!this.form || !this.form.fields) return;
      this.reformatAllField();
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = this.form.fields[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var f = _step5.value;
          Ember.set(f, 'validationError', undefined);
          Ember.set(f, 'responsesErrors', undefined);

          if (f.required) {
            var fieldValue = '' + (f.value ? f.value : '');
            var tmp = fieldValue.split('||');
            var responsesErrors = [];
            var responseIndex = 0;
            var _iteratorNormalCompletion6 = true;
            var _didIteratorError6 = false;
            var _iteratorError6 = undefined;

            try {
              for (var _iterator6 = tmp[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
                var value = _step6.value;
                var formatedValue = value.trim();

                if (!formatedValue || formatedValue.length == 0) {
                  Ember.set(f, 'validationError', this.intl.t('form.errors.validation.required'));
                  responsesErrors.push(responseIndex);
                  isOK = false;
                }

                if (f.type === 'multiple_choice' && f.properties && f.properties.allow_multiple_selection) {
                  try {
                    if (formatedValue[0] == '"') {
                      formatedValue = formatedValue.substring(1);
                    }

                    if (formatedValue[formatedValue.length - 1] == '"') {
                      formatedValue = formatedValue.substring(0, formatedValue.length - 1);
                    }

                    formatedValue = formatedValue.replaceAll('\\"', '"');

                    try {
                      formatedValue = JSON.parse(formatedValue);
                    } catch (err) {}

                    if (formatedValue.length === 0) {
                      Ember.set(f, 'validationError', this.intl.t('form.errors.validation.required'));
                      isOK = false;
                      responsesErrors.push(responseIndex);
                    }
                  } catch (err) {
                    Ember.set(f, 'validationError', this.intl.t('form.errors.validation.required'));
                    isOK = false;
                    responsesErrors.push(responseIndex);
                  }
                }

                responseIndex++;
              }
            } catch (err) {
              _didIteratorError6 = true;
              _iteratorError6 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                  _iterator6.return();
                }
              } finally {
                if (_didIteratorError6) {
                  throw _iteratorError6;
                }
              }
            }

            Ember.set(f, 'responsesErrors', responsesErrors);
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      ;
      return isOK;
    },
    actions: {
      onCancel: function onCancel() {
        if (this.onCancel) this.onCancel();
      },
      send: function send() {
        var _this = this;

        var isDrafting = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        Ember.set(this, 'showMissingRequiredFields', false);

        if (!isDrafting && !this.validateAllFields()) {
          Ember.set(this, 'showMissingRequiredFields', true);
          return;
        } else if (isDrafting) {
          this.reformatAllField();
        }

        if (this.canRespond) {
          var formResponseData;

          if (this.form.toJSON) {
            formResponseData = this.form.toJSON();
          } else {
            formResponseData = this.form;
          }

          formResponseData.talentId = this.talentId;
          formResponseData.formId = this.form.id;
          formResponseData.isDraft = isDrafting;
          var formMustBeReviewed = formResponseData.mustBeReviewed;
          var path = _environment.default.APP.API_HOST + '/forms/' + this.form.id + '/response';
          this.ajax.requestPromise(_environment.default.APP.API_HOST + '/forms/' + this.form.id + '/response/' + this.talentId, 'GET').then(function (oldForm) {
            if (_this.allowDocumentValidation) {
              formResponseData.fields.forEach(function (f) {
                if (oldForm && Array.isArray(oldForm.fields)) {
                  var oldField = oldForm.fields.find(function (of) {
                    return of.id == f.id;
                  });

                  if (f.isValid != null && f.isValid == false && oldField.value && f.value && oldField.id == f.id && oldField.value != f.value) {
                    Ember.set(f, 'isValid', null);
                    Ember.set(f, 'rejectReason', null);
                  }
                }
              });
              var allFieldsUpdated = true;
              formResponseData.fields.forEach(function (f) {
                if (f.rejectReason && f.rejectReason.length > 0) {
                  allFieldsUpdated = false;
                }
              });

              if (!allFieldsUpdated && !isDrafting) {
                _this.notify.error(_this.intl.t('form.validation.update_all_reject_documents'), {
                  closeAfter: 10000
                });

                return;
              }

              if (!isDrafting && formResponseData.mustBeReviewed) {
                Ember.set(formResponseData, 'mustBeReviewed', false);
              }
            }

            _this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(formResponseData)).then(function (form) {
              if (_this.form.confirmationMessage && !form.isDraft) {
                Ember.set(_this, 'showConfirmationAtEnd', true);
              }

              if (_this.form.type === "QUIZZ" && _this.form.showFinalScore && form.result) {
                Ember.set(_this, 'showFinalScore', true);
                Ember.set(_this, 'result', form.result);
              }

              if (!_this.showConfirmationAtEnd && !_this.showFinalScore && _this.onCancel) {
                _this.onCancel();
              }

              if (_this.onResponseSent && !isDrafting && (!_this.responseMode || _this.responseMode && formMustBeReviewed)) _this.onResponseSent();

              _this.eventBus.trigger('REFRESH_TASKS_COUNT');
            }).catch(function (err) {
              if (err && err.responseJSON && err.responseJSON.code == "FILE_ALREADY_DELETED") {
                _this.notify.alert(_this.intl.t("form.validation.file_already_deleted"));
              }
            });
          });
        }
      },
      sendComments: function sendComments() {
        var _this2 = this;

        var path = _environment.default.APP.API_HOST + '/forms/' + this.form.id + '/comments';
        var formResponseData;

        if (this.form.toJSON) {
          formResponseData = this.form.toJSON();
        } else {
          formResponseData = this.form;
        }

        formResponseData.talentId = this.talentId;
        formResponseData.formId = this.form.id;
        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(formResponseData)).then(function () {
          _this2.send('onCancel');
        });
      },
      switchResponses: function switchResponses(responseIndex) {
        var openedResponses = this.openedResponses;
        var index = openedResponses.indexOf(responseIndex);

        if (index > -1) {
          openedResponses.splice(index, 1);
        } else {
          openedResponses.push(responseIndex);
        }

        Ember.set(this, 'openedResponses', undefined);
        Ember.set(this, 'openedResponses', openedResponses);
      },
      addNewReponse: function addNewReponse() {
        if (this.currentResponseNumber < this.form.maxResponseNumber) {
          Ember.set(this, 'currentResponseNumber', this.currentResponseNumber + 1);
          this.send('switchResponses', this.currentResponseNumber - 1);
        }
      }
    }
  });

  _exports.default = _default;
});