define("teelt-frontend/templates/components/form/form-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MHSONMYc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"translucentOverlay\",\"containerClass\",\"onClickOverlay\"],[[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"show\"]]],null],false],null],true,\"form-modal\",[27,\"action\",[[22,0,[]],\"onClickOverlay\"],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"btn-close btn-transparent\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[7,\"i\"],[11,\"class\",\"material-icons\"],[9],[0,\"close\"],[10],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"form\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"form\",\"fields\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"form/form-preview\",null,[[\"form\",\"isDraft\",\"canRespond\",\"responseMode\",\"canEdit\",\"onCancel\",\"talentId\",\"onResponseSent\",\"canComment\"],[[23,[\"form\"]],[23,[\"isDraft\"]],[27,\"or\",[[27,\"not\",[[23,[\"responseMode\"]]],null],[23,[\"canEdit\"]],[23,[\"isDraft\"]]],null],[23,[\"responseMode\"]],[23,[\"canEdit\"]],[27,\"action\",[[22,0,[]],\"close\"],null],[23,[\"talentId\"]],[27,\"action\",[[22,0,[]],\"onResponseSent\"],null],[23,[\"canComment\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "teelt-frontend/templates/components/form/form-modal.hbs"
    }
  });

  _exports.default = _default;
});