define("teelt-frontend/models/tool", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    category: _emberData.default.attr(),
    logoUrl: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    companyId: _emberData.default.attr('number'),
    audience: _emberData.default.attr(),
    genericTool: _emberData.default.attr(),
    conditions: _emberData.default.attr()
  });

  _exports.default = _default;
});