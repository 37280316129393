define("teelt-frontend/helpers/activity/override-email-activity-translation-with-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 4),
          activityType = _ref2[0],
          translationKey = _ref2[1],
          notif = _ref2[2],
          withoutFirstPart = _ref2[3];

      var translation = this.intl.t(translationKey, {
        notif: notif
      });
      var part1 = translation.split(':')[0];
      var part2 = translation.split(':')[1];

      if (notif.startsWith("NEW_ACCOUNT_")) {
        part2 = " " + this.intl.t('send_notification.system.INVIT');
      }

      if (withoutFirstPart) {
        return part2;
      }

      part1 = "<span class='email-status " + activityType + " ' >" + part1 + "</span>";
      return part1 + ":" + part2;
    }
  });

  _exports.default = _default;
});