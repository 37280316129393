define("teelt-frontend/routes/board/setting/privacy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      handleFocusOut: function handleFocusOut() {
        this.currentModel.company.save();
      },
      interceptEnter: function interceptEnter(e1, e2) {
        if (e2.keyCode == 13) {
          this.send('handleFocusOut', e2);
          return false;
        }

        return true;
      },
      inactivityInterceptEnter: function inactivityInterceptEnter(e1, e2) {
        if (e2.which > 48 && e2.which < 53) {
          e2.preventDefault();
        }

        this.send("interceptEnter", e1, e2);
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        if (this.currentModel.company.essential) {
          setTimeout(function () {
            var elements = document.querySelectorAll('.items-container');

            if (elements && elements.length > 2) {
              for (var i = 0; i < elements.length; i++) {
                if (i > 1) {
                  elements[i].style.display = 'none';
                }
              }
            }
          }, 0);
        }
      }
    }
  });

  _exports.default = _default;
});