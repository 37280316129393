define("teelt-frontend/helpers/avg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function avg(params
  /*, hash*/
  ) {
    var allData = Number(params[0]);
    var data = Number(params[1]);
    var avg = 0;

    if (params && allData && data) {
      avg = data / allData * 100;
    }

    return avg.toFixed(2);
  });

  _exports.default = _default;
});