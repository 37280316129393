define("teelt-frontend/components/talents/talents-periods-slick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['talents-periods-slick'],
    userService: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var periods = this.userService.getTalentPeriods(this.talent);
      Ember.set(this, 'periods', periods);
      Ember.set(this, 'talentCurrentPeriod', this.userService.getTalentCurrentPeriod(this.talent, periods));
      this.setHighlight();
      this.displayFinishCard();
    },
    displayFinishCard: function displayFinishCard() {
      var periods = this.periods;
      var isHighlight = this.talent.talentProgression === 100 ? true : false;
      periods.push({
        key: 'FINISH',
        label: this.intl.t("task.period.FINISH"),
        index: 3,
        highlight: isHighlight
      });
      Ember.set(this, 'periods', periods);
    },
    setHighlight: function setHighlight() {
      var _this = this;

      var periods = this.periods;
      periods.forEach(function (period) {
        Ember.set(period, "highlight", true);

        _this.talent.remainingTalentTasks.forEach(function (task) {
          if (task.period === period.key) {
            Ember.set(period, "highlight", false);
          }
        });
      });
      Ember.set(this, 'periods', periods);
    },
    destroy: function destroy() {
      this.eventBus.off('TOGGLE_BOTTOM_PANNEL', this, 'toggleBottomPannel');

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this.$('.slick-container').slick({
        initialSlide: this.talentCurrentPeriod >= 0 ? this.talentCurrentPeriod : 0,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        dots: true,
        centerPadding: '40px',
        arrows: false
      });
    },
    actions: {
      toggleBottomPannel: function toggleBottomPannel(period) {
        if (period.key == 'FINISH') {
          this.router.transitionTo('talent.home');
        } else {
          this.eventBus.trigger('TOGGLE_BOTTOM_PANNEL', period);
        }
      }
    }
  });

  _exports.default = _default;
});