define("teelt-frontend/components/myaccount/myaccount-config", ["exports", "teelt-frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blockSave: false,
    defaultProfileImageUrl: _environment.default.APP.DEFAULT_PROFILE_IMAGE_URL,
    session: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    showLangs: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.findRecord('user', this.session.data.authenticated.user.id, {
        reload: true
      }).then(function (talent) {
        Ember.set(_this, 'talent', talent.toJSON());
        Ember.set(_this, 'userIsRh', _this.userService.getCurrentRole() == "ROLE_RH");
        Ember.set(_this, 'userIsCustomOfficeManager', _this.userService.getCurrentRole() == "ROLE_CUSTOM_OFFICE_MANAGER");
        Ember.set(_this, 'showMonthlyReport', _this.userService.getCurrentRole() == "ROLE_MRH" || _this.userService.getCurrentRole() == "ROLE_CUSTOM_OFFICE_MANAGER" || _this.userService.getCurrentRole() == "ROLE_MANAGER");
      });

      if (this.menuToOpen && ['notifs', 'infos'].includes(this.menuToOpen)) {
        this.send('open', this.menuToOpen);
      }
    },
    onProfilePictureProgress: function onProfilePictureProgress(e) {
      if (e.lengthComputable) {
        var progress = Math.ceil(e.loaded / e.total * 100);
        Ember.set(this, 'profilePictureProgress', progress);
      }
    },
    actions: {
      open: function open(menu) {
        Ember.set(this, 'menu', menu);

        if (menu == 'infos') {
          setTimeout(function () {
            (0, _jquery.default)('.select-lang').formSelect();
          });
        }
      },
      changeProfilePicture: function changeProfilePicture() {
        this.$('#profile-picture button').click();
      },
      onProfilePictureChanged: function onProfilePictureChanged(file) {
        var _this2 = this;

        if (!file) return;
        Ember.set(this, 'blockSave', true);
        this.fileUploadService.signAndUpload(file, false, function (e) {
          return _this2.onProfilePictureProgress(e);
        }).then(function (fileUrl) {
          Ember.set(_this2.talent, "profilePictureUrl", fileUrl);
        }).finally(function () {
          Ember.set(_this2, 'blockSave', false);
        });
      },
      notifChanged: function notifChanged(notif, event) {
        Ember.set(this.talent, notif, !event.target.checked);
      },
      onLangChanged: function onLangChanged(value) {
        Ember.set(this.talent, "lang", value);
      },
      save: function save() {
        var _this3 = this;

        Ember.set(this, 'blockSave', true);
        var path = _environment.default.APP.API_HOST + "/users/profile";
        Ember.set(this, 'showLangs', false);
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.talent)).then(function (result) {
          if (result) {
            Ember.set(_this3, 'blockSave', false);
            Ember.set(_this3.session.data.authenticated.user, "lang", _this3.talent.lang);

            _this3.session.store.persist(_this3.session.data);

            _this3.intl.setLocale([_this3.talent.lang, 'en', 'fr']);

            Ember.set(_this3, 'showLangs', true);

            if (_this3.menu == 'infos') {
              setTimeout(function () {
                (0, _jquery.default)('.select-lang').formSelect();
              });
            }

            _this3.notify.info(_this3.intl.t('talent.modifications_saved'), {
              closeAfter: 5000
            });
          }
        });
      },
      openModifyPasswordModal: function openModifyPasswordModal() {
        Ember.set(this, 'showModifyPasswordModal', true);
      }
    }
  });

  _exports.default = _default;
});