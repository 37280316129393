define("teelt-frontend/components/content/content-element", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['content-element'],
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload'),
    customErrorService: Ember.inject.service('custom-error'),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    contentService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.set(this, 'originalTitle', this.content.title);
      Ember.set(this, 'originalMediaUrl', this.content.mediaUrl);
      Ember.set(this, 'originalFullValue', this.content.fullValue);
      this.refreshContent();
      var routeName = this.router.get('currentRouteName');
      Ember.set(this, "routeName", routeName);
    },
    onForceTagChanged: Ember.observer('forceTag', function () {
      this.refreshContent();
    }),
    refreshContent: function refreshContent() {
      var tags = this.session.data.authenticated.user.tags ? this.session.data.authenticated.user.tags.map(function (t) {
        return t.id;
      }) : [];

      if (this.forceTag !== undefined && this.forceTag !== -1) {
        tags = [Number(this.forceTag)];
      }

      Ember.set(this.content, 'title', this.originalTitle);
      Ember.set(this.content, 'mediaUrl', this.originalMediaUrl);
      Ember.set(this.content, 'fullValue', this.originalFullValue);
      Ember.set(this.content, 'tagId', undefined);
      Ember.set(this, 'rand', new Date());
    },
    manageAllSelectedLabel: function manageAllSelectedLabel(content) {
      var els = document.querySelectorAll('.selected-icon');
      var isAllSelected = false;

      if (!content.selected) {
        var i = 0;
        els.forEach(function (el) {
          if (el.firstElementChild === null) {
            i++;
          }
        });
        if (i <= 1) isAllSelected = true;
      }

      var el = document.querySelector('.select-all-contents-btn');

      if (el) {
        el.innerHTML = isAllSelected ? this.intl.t('content.unselect_all') : this.intl.t('content.select_all');
      }
    },
    actions: {
      select: function select(content) {
        if (!this.onOpenContentDetail) {
          this.manageAllSelectedLabel(content);
          Ember.set(content, 'selected', !content.selected);
        }

        if (this.onSelect) {
          this.onSelect(content);
        }
      },
      assignContent: function assignContent(content) {
        this.router.transitionTo('board.content.content-assign', content.id);
      },
      onEditConditions: function onEditConditions(content) {
        Ember.set(this, 'showActions', false);
        if (this.onEditConditions) this.onEditConditions(content);
      },
      triggerCard: function triggerCard() {
        if (this.onSelect) return;

        if (this.content.type === "textcard" || this.content.type === "video" || this.content.type === "slideshow") {
          this.openContentDetail();
        } else if (this.content.type === "filecard") {
          this.openFileCard();
        }
      },
      copyContentUrlToClipboard: function copyContentUrlToClipboard(content) {
        var subdomain = window.location.host;
        var url = subdomain + '/talent/integration/resources?contentId=' + content.id;

        if (!subdomain || !url) {
          this.notify.error("An error occurred while copying the link", {
            closeAfter: 5000
          });
          return;
        }

        var tempElem = document.createElement("input");
        document.body.appendChild(tempElem);
        tempElem.setAttribute('value', url);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        this.notify.info(this.intl.t('module.module_link_copied_to_clipboard'), {
          closeAfter: 5000
        });
      },
      editContent: function editContent(content) {
        var _this = this;

        Ember.set(this, 'showActions', false);
        Ember.set(this, 'showConfirmContent', true);
        this.store.findRecord('content', content.id, {
          reload: true
        }).then(function (updateContent) {
          Ember.set(_this, 'updateContent', updateContent);
        });
      },
      onFinish: function onFinish() {
        Ember.set(this, 'updateContent', undefined);
        Ember.set(this, 'updateVersion', undefined);
        Ember.set(this, 'showConfirmContent', false);
        Ember.set(this, 'showConfirmVersion', false);
        if (this.editContent) this.editContent();
      },
      previous: function previous() {
        Ember.set(this, 'updateContent', undefined);
        Ember.set(this, 'updateVersion', undefined);
        Ember.set(this, 'showConfirmContent', false);
        Ember.set(this, 'showConfirmVersion', false);
      },
      openEditVersion: function openEditVersion(content) {
        Ember.set(this, 'showUpdateVersion', true);
        Ember.set(this, 'updateContent', undefined);
        Ember.set(this, 'updateVersion', content);
      },
      editVersion: function editVersion(content) {
        var _this2 = this;

        Ember.set(this, 'showConfirmVersion', true);
        Ember.set(this, 'showActions', false);
        Ember.set(this, 'updateContent', undefined);
        Ember.set(this, 'updateVersion', undefined);
        Ember.set(this, 'updateVersionContent', content);
        this.store.findRecord('content', content.id).then(function (result) {
          Ember.set(_this2, 'updateVersion', result);
        });
      },
      closeEditContent: function closeEditContent() {
        Ember.set(this, 'showUpdateContent', false);
        Ember.set(this, 'showConfirmContent', false);
        Ember.set(this, 'updateContent', undefined);
      },
      closeEditVersion: function closeEditVersion() {
        Ember.set(this, 'showUpdateVersion', false);
        Ember.set(this, 'showConfirmVersion', false);
        Ember.set(this, 'updateVersion', undefined);
        Ember.set(this, 'updateVersionContent', undefined);
      }
    },
    openContentDetail: function openContentDetail() {
      var _this3 = this;

      if (this.content.fullValue) {
        this.openContentDetailWithFullValue();
      } else {
        if (this.content.id) {
          var path = _environment.default.APP.API_HOST + '/contents/' + this.content.id;
          this.ajax.requestPromise(path).then(function (content) {
            Ember.set(_this3.content, 'fullValue', content.fullValue);

            _this3.openContentDetailWithFullValue();
          });
        }
      }
    },
    openContentDetailWithFullValue: function openContentDetailWithFullValue() {
      if (this.onOpenContentDetail) {
        this.onOpenContentDetail(this.content);
      } else {
        Ember.set(this, 'contentDetail', this.content);
        Ember.set(this, 'showContentDetailPopup', true);
      }
    },
    openFileCard: function openFileCard() {
      var _this4 = this;

      if (!this.content.fullValue || this.content.fullValue.trim() == '') return; // WARNING : Always open tab before fetch temporary url because Firefox authorize opened tab by user click only and not in promise resolve

      Ember.set(this, "showDownloadingModal", true);
      this.fileUploadService.getObjectUrl(this.content.fullValue).then(function (data) {
        var url = data.url;

        if (!_this4.content.toDownload) {
          url += "&inline";
        }

        window.open(url, 'newtab');
        Ember.set(_this4, "showDownloadingModal", false);
      }).catch(function (err) {
        _this4.customErrorService.handleAjaxError(err);
      });
    }
  });

  _exports.default = _default;
});