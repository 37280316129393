define("teelt-frontend/routes/board/setting/schedule-sftp-export", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {},
    actions: {
      open: function open(page) {
        this.transitionTo('board.setting.' + page);
      }
    }
  });

  _exports.default = _default;
});