define("teelt-frontend/components/form/field/field-choice-preview", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['field-choice-preview'],
    ajax: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (Object.keys(this.field.possibleResponses).length > 4) {
        Ember.set(this, "choicesScroll", true);
      }

      if (Object.keys(this.field.possibleResponses).length > 10) {
        Ember.set(this, "choicesSearch", true);
      }

      this.sortResponses();
      var canShowResponse = false;

      if (this.responseMode || !this.formId) {
        canShowResponse = true;
      } else if (this.field.properties && this.field.properties.multiple_choice_quantify) {
        var promises = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          var _loop = function _loop() {
            var possibleResponse = _step.value;
            var path = _environment.default.APP.API_HOST + '/forms/' + _this.formId + '/fields/' + _this.field.id + '/responses/count?response=' + encodeURIComponent(possibleResponse.value);
            promises.push(_this.ajax.requestPromise(path, 'GET').then(function (count) {
              var quantity = possibleResponse.quantity ? possibleResponse.quantity : Number.MAX_VALUE;
              Ember.set(possibleResponse, 'disabled', count >= quantity);
              Ember.set(possibleResponse, 'count', count);
            }));
          };

          for (var _iterator = this.field.possibleResponses[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            _loop();
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        Promise.all(promises).then(function () {
          Ember.set(_this, 'showQuantity', true);
          Ember.set(_this, 'canShowResponse', true);
        });
      } else {
        canShowResponse = true;
      }

      setTimeout(function () {
        Ember.set(_this, 'possibleResponses', _this.field.possibleResponses);
      }, 100);
      Ember.set(this, 'canShowResponse', canShowResponse);
    },
    didInsertElement: function didInsertElement() {
      if (!this.value && this.field.properties && this.field.properties.allow_multiple_selection) {
        Ember.set(this, 'value', []);
      } else if (this.value && this.field.properties && this.field.properties.allow_multiple_selection && typeof this.value == 'string') {
        try {
          Ember.set(this, 'value', JSON.parse(this.value));
        } catch (err) {
          try {
            Ember.set(this, 'value', JSON.parse(this.decodeHtmlEntities(this.value)));
          } catch (e) {}
        }
      } else {
        try {
          Ember.set(this, 'value', "" + JSON.parse(this.value));
        } catch (err) {}
      }

      this.initIsCheckedFieldWithOneSelection();
    },
    decodeHtmlEntities: function decodeHtmlEntities(encodedStr) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = encodedStr;
      return textArea.innerText;
    },
    sortResponses: function sortResponses() {
      if (!this.field.possibleResponses) return;
      var responses = this.field.possibleResponses;
      var sortedResponses = responses.sort(function (a, b) {
        if (a.value < b.value) {
          return -1;
        }

        if (a.value > b.value) {
          return 1;
        }

        return 0;
      });
      Ember.set(this.field, "possibleResponses", sortedResponses);
    },
    initIsCheckedFieldWithOneSelection: function initIsCheckedFieldWithOneSelection() {
      var _this2 = this;

      var responsesArray = this.field.possibleResponses;
      responsesArray.forEach(function (response) {
        if (_this2.value == response.value) {
          Ember.set(response, "isChecked", true);
        }
      });
      Ember.set(this.field, "possibleResponses", responsesArray);
    },
    searchResponse: function searchResponse() {
      var _this3 = this;

      Ember.set(this, 'canShowResponse', false);
      var filteredPossibleResponses = this.field.possibleResponses.filter(function (r) {
        return r.value.indexOf(_this3.search) >= 0;
      });
      Ember.set(this, 'possibleResponses', filteredPossibleResponses);
      Ember.set(this, 'canShowResponse', true);
    },
    actions: {
      setValue: function setValue(value, disabled) {
        if (this.disabled) return;
        if (disabled) return;
        if (this.responseMode) return;
        Ember.set(this, 'value', value);
      },
      triggerCheckbox: function triggerCheckbox(value, disabled) {
        if (this.disabled) return;
        if (disabled) return;
        if (this.responseMode) return;
        var values = this.value;

        if (values.includes(value)) {
          values.removeObject(value);
        } else {
          values.pushObject(value);
        }

        Ember.set(this, 'value', undefined);
        Ember.set(this, 'value', values);
      },
      searchResponse: function searchResponse() {
        var _this4 = this;

        if (this.keyUpAction) {
          Ember.run.cancel(this.keyUpAction);
        }

        var keyUpAction = Ember.run.later(function () {
          _this4.searchResponse();
        }, 200);
        Ember.set(this, "keyUpAction", keyUpAction);
      }
    }
  });

  _exports.default = _default;
});