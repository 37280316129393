define("teelt-frontend/components/settings/table/scheduled-other-sftp-export-table-content", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['perimeter-list'],
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    notify: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    elementToEdit: {},
    editAutoExportDocumentValidation: function editAutoExportDocumentValidation(result) {
      var _this = this;

      if (!result || !result.companyId || !result.key || result.key != "auto_export_document_validation") return;
      this.ajax.requestPromise(_environment.default.APP.API_HOST + '/companies/' + result.companyId + '/toggle-auto-export-document-validation', 'PATCH').then(function () {
        _this.eventBus.trigger('REFRESH_SCHEDULED_OTHER_SFTP');
      });
    },
    actions: {
      initSchedule: function initSchedule(result) {
        if (!result.activeDocumentValidationAutoExport || !this.allowScheduleAutoExport) return;
        this.eventBus.trigger('SCHEDULED_OTHER_SFTP', result);
      },
      edit: function edit(result) {
        if (!this.allowScheduleAutoExport) return;

        switch (result.key) {
          case 'auto_export_document_validation':
            this.editAutoExportDocumentValidation(result);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});