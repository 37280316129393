define("teelt-frontend/components/tool/tools-list", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tools-list'],
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajax: Ember.inject.service(),
    search: '',
    canAdd: true,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.store.unloadAll('tool');

      if (this.template && !this.activeTrigger) {
        Ember.set(this, 'tools', this.template.tools);
      } else if (this.template) {
        var selectedToolIds = [];

        if (this.template.tools) {
          this.template.tools.forEach(function (tool) {
            selectedToolIds.push(tool.id);
          });
        }

        Ember.set(this, 'selectedIds', selectedToolIds);
      }

      if (!this.tools && this.activeTrigger) {
        this.store.findAll('tool').then(function (tools) {
          var groupedTools = _this.groupToolsByCategory(tools);

          Ember.set(_this, 'groupedTools', groupedTools);
          Ember.set(_this, "selectedCategories", groupedTools);
        });
      } else if (this.tools) {
        var groupedTools = this.groupToolsByCategory(this.tools);
        Ember.set(this, 'groupedTools', groupedTools);
        Ember.set(this, 'displayMode', true);
        Ember.set(this, "selectedCategories", groupedTools);
      }

      this.initDrake();
    },
    toolsObserver: Ember.observer('tools', function () {
      var groupedTools = this.groupToolsByCategory(this.tools);
      Ember.set(this, 'groupedTools', groupedTools);
      Ember.set(this, "selectedCategories", groupedTools);
    }),
    templateToolObserver: Ember.observer('template.tools', function () {
      Ember.set(this, 'tools', this.template.tools);
    }),
    actions: {
      close: function close() {
        if (this.close) {
          this.close();
        }
      },
      showToolAction: function showToolAction(tool) {
        if (this.showActions == tool.id) {
          Ember.set(this, 'showActions', false);
          return;
        }

        Ember.set(this, 'showActions', tool.id);
      },
      onEditConditions: function onEditConditions(tool) {
        Ember.set(this, 'toolToEdit', tool);
      },
      setConfidential: function setConfidential(bool) {
        var _this2 = this;

        if (!this.toolToEdit) return;
        var tool = this.toolToEdit;
        var conditions = {
          afterTalentArrival: bool
        };
        var path = _environment.default.APP.API_HOST + '/tools/' + tool.id + '/conditions';
        this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(conditions)).then(function () {
          var conditionsObject = {};

          if (tool.conditions) {
            conditionsObject = JSON.parse(tool.conditions);
          }

          for (var key in conditions) {
            conditionsObject[key] = conditions[key];
          }

          Ember.set(tool, 'conditions', JSON.stringify(conditionsObject));
          var tools = _this2.tools;
          var index = tools.findIndex(function (t) {
            return t.id === tool.id;
          });
          tools[index] = tool;
          Ember.set(_this2, 'tools', tools);
          setTimeout(function () {
            Ember.set(_this2, 'toolToEdit', false);
            Ember.set(_this2, 'showActions', false);
          }, 100);
        });
      },
      onClickOverlay: function onClickOverlay() {
        Ember.set(this, 'toolToEdit', false);
        Ember.set(this, 'showActions', false);
      },
      selectTools: function selectTools() {
        var _this3 = this;

        if (this.template) {
          var apiUrl = _environment.default.APP.API_HOST + "/templates/" + this.template.id + "/tools";
          this.ajax.requestWithBodyPromise(apiUrl, 'PATCH', 'application/json', JSON.stringify(this.selectedIds)).then(function (tools) {
            _this3.send('close');

            Ember.set(_this3.template, 'tools', tools);
          });
        }
      },
      searchTools: function searchTools(search) {
        var _this4 = this;

        this.set('search', search);
        var params = {
          search: this.search
        };
        this.store.query('tool', params).then(function (tools) {
          var groupedTools = _this4.groupToolsByCategory(tools);

          Ember.set(_this4, 'groupedTools', groupedTools);
          Ember.set(_this4, 'selectedCategories', groupedTools);
        });
      },
      triggerTool: function triggerTool(tool) {
        if (this.canTriggerTool) {
          Ember.set(tool, 'selected', !tool.selected);

          if (this.selectedIds) {
            if (tool.selected) {
              this.selectedIds.addObject(Number(tool.id));
            } else {
              this.selectedIds.removeObject(Number(tool.id));
            }
          }
        } else {
          Ember.set(this, 'toolToShow', tool);
          Ember.set(this, 'showToolPopup', true);
        }
      },
      closeShowToolPopup: function closeShowToolPopup() {
        Ember.set(this, 'showToolPopup', false);
      },
      switchCategory: function switchCategory(category) {
        if (this.selectedCategories.includes(category)) {
          var categories = this.selectedCategories.filter(function (value, index, arr) {
            return value !== category;
          });
          Ember.set(this, 'selectedCategories', categories);
        } else {
          var _categories = this.selectedCategories;

          _categories.push(category);

          Ember.set(this, 'selectedCategories', undefined);
          Ember.set(this, 'selectedCategories', _categories);
        }
      }
    },
    groupToolsByCategory: function groupToolsByCategory(tools) {
      var _this5 = this;

      if (!tools) return {};
      var groupedTools = {
        others: {
          name: this.intl.t('tool.others'),
          tools: []
        }
      };
      tools.forEach(function (tool) {
        if (_this5.activeTrigger && _this5.selectedIds && _this5.selectedIds.includes(Number(tool.id))) {
          Ember.set(tool, 'selected', true);
        }

        if (!tool.category) {
          groupedTools.others.tools.pushObject(tool);
          return;
        }

        var categoryId = tool.category.id;
        var categoryName = tool.category.name;
        var categoryOrder = tool.category.order;

        if (!groupedTools[categoryName]) {
          groupedTools[categoryName] = {
            id: categoryId,
            name: categoryName,
            order: categoryOrder,
            tools: []
          };
        }

        groupedTools[categoryName].tools.pushObject(tool);
      });
      var groupedToolsArray = [];

      for (var name in groupedTools) {
        groupedTools[name].tools.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        if (name !== 'others') {
          groupedToolsArray.push(groupedTools[name]);
        }
      }

      groupedToolsArray.sort(function (a, b) {
        return a.order > b.order ? 1 : -1;
      });
      Ember.set(this, 'selectedCategories', groupedTools);
      groupedToolsArray.push(groupedTools['others']);
      return groupedToolsArray;
    },
    clearDrake: function clearDrake() {
      if (this.drake) {
        this.drake.destroy();
        Ember.set(this, 'drake', undefined);
      }
    },
    initDrake: function initDrake() {
      var _this6 = this;

      setTimeout(function () {
        _this6.clearDrake();

        var self = _this6;

        var tmp = _this6.$('.tools-movable');

        var containers = [];

        if (tmp && tmp.length) {
          containers.push(tmp[0]);
        }

        var drake = dragula({
          containers: containers,
          revertOnSpill: false,
          accepts: function accepts(el, target, source, sibling) {
            // accept drags only if the drop target is the same
            // as the source container the element came from
            return target == source;
          }
        });
        drake.on('drop', function (el, container, source, sibling) {
          var orders = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = container.childNodes[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var child = _step.value;

              if (child.getAttribute && child.getAttribute("data-index")) {
                var index = parseInt(child.getAttribute("data-index"));
                orders.push(self.groupedTools[index].id);
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          if (orders.length > 1) {
            var path = _environment.default.APP.API_HOST + '/tools/orders';
            self.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(orders));
          }
        });
        Ember.set(_this6, 'drake', drake);
      }, 1000);
    }
  });

  _exports.default = _default;
});