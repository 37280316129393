define("teelt-frontend/components/form/forms-list-talent", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    classNames: ['forms-list-talent'],
    ajax: Ember.inject.service(),
    eventBus: Ember.inject.service(),
    session: Ember.inject.service(),
    userService: Ember.inject.service(),
    fileUploadService: Ember.inject.service('file-upload')
  }, _defineProperty(_EmberComponent$exte, "session", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "notify", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "intl", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "store", Ember.inject.service()), _defineProperty(_EmberComponent$exte, "selectedItem", {}), _defineProperty(_EmberComponent$exte, "validDocsNb", 0), _defineProperty(_EmberComponent$exte, "allowSftpStorage", false), _defineProperty(_EmberComponent$exte, "allowDocumentValidation", false), _defineProperty(_EmberComponent$exte, "canCloseDocumentViewerWithoutSaving", true), _defineProperty(_EmberComponent$exte, "signedDocuments", []), _defineProperty(_EmberComponent$exte, "init", function init() {
    var _this = this;

    this._super.apply(this, arguments);

    this.eventBus.on('FORCE_OPEN_FORM', this, 'forceOpenForm');
    var currentRole = this.userService.getCurrentRole();

    switch (currentRole) {
      case 'ROLE_ADMINISTRATIVE':
        Ember.set(this, 'currentRole', 'administrative');
        break;

      case 'ROLE_CUSTOM_OFFICE_MANAGER':
        Ember.set(this, 'currentRole', 'officeManager');
        break;

      case 'ROLE_MANAGER':
        Ember.set(this, 'currentRole', 'manager');
        break;

      case 'ROLE_RH':
        Ember.set(this, 'currentRole', 'rh');
        break;

      case 'ROLE_MRH':
        Ember.set(this, 'currentRole', 'adminMRH');
        break;

      default:
        Ember.set(this, 'currentRole', '');
    }

    if (this.forms) {
      var user = this.session.data.authenticated.user;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.forms[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var form = _step.value;
          Ember.set(form, 'canViewResponses', false);
          var canViewRoles = ['ROLE_RH', 'ROLE_CUSTOM_OFFICE_MANAGER', 'ROLE_MRH'];
          var canCommentRoles = [];

          if (form.permitComment) {
            canCommentRoles = ['ROLE_RH', 'ROLE_MRH'];
            canCommentRoles = canCommentRoles.concat(form.commentRoles);
          }

          if (form.viewResultRoles) {
            canViewRoles = canViewRoles.concat(form.viewResultRoles);
          }

          if (form.commentRoles) {
            canViewRoles = canViewRoles.concat(form.commentRoles);
          }

          if (this.userService.isAdministrative(user) && !canViewRoles.includes('ROLE_ADMINISTRATIVE') && form.type == 'ADMINISTRATIVE') {
            canViewRoles.push('ROLE_ADMINISTRATIVE');
          }

          if (this.userService.hasAnyRoleArray(user, canViewRoles)) {
            Ember.set(form, 'canViewResponses', true);
          }

          if (form.anonymous && user.id != this.talent.id) {
            Ember.set(form, 'canViewResponses', false);
          }

          if (this.userService.hasAnyRoleArray(user, canCommentRoles)) {
            Ember.set(form, 'canComment', true);
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    this.ajax.requestPromise(_environment.default.APP.API_HOST + '/sftps', 'GET').then(function (sftp) {
      if (!sftp || !_this.session.data.authenticated.user.company.activeSftp) {
        Ember.set(_this, 'allowSftpStorage', false);
      } else {
        Ember.set(_this, 'allowSftpStorage', true);
      }
    });

    if (this.session.data.authenticated.user.company.activeDocumentValidation) {
      Ember.set(this, 'allowDocumentValidation', true);
    }
  }), _defineProperty(_EmberComponent$exte, "didInsertElement", function didInsertElement() {
    this._super.apply(this, arguments);

    this.signedDocuments.length = 0;
  }), _defineProperty(_EmberComponent$exte, "countVerifiedDocs", Ember.computed('formToValidate.fields.@each.isValid', function () {
    var count = 0;

    if (this.formToValidate) {
      this.formToValidate.fields.forEach(function (element) {
        if (element.isValid != null) {
          count++;
        }
      });
      return count;
    }

    return;
  })), _defineProperty(_EmberComponent$exte, "destroy", function destroy() {
    this.eventBus.off('FORCE_OPEN_FORM', this, 'forceOpenForm');

    this._super.apply(this, arguments);
  }), _defineProperty(_EmberComponent$exte, "forceOpenForm", function forceOpenForm(formId, canResponse) {
    if (this.forms && formId) {
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = this.forms[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var form = _step2.value;

          if (form.id == formId) {
            if (canResponse) {
              this.send('startForm', form);
            } else {
              this.send('showFormResponse', form);
            }

            break;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    }
  }), _defineProperty(_EmberComponent$exte, "forms", Ember.computed('talent', 'isAdministrative', function () {
    var _this2 = this;

    if (!this.talent.selectedTemplate || !this.talent.selectedTemplate.periods) return;
    var forms = [];

    if (this.talent.userForms) {
      var _iteratorNormalCompletion3 = true;
      var _didIteratorError3 = false;
      var _iteratorError3 = undefined;

      try {
        for (var _iterator3 = this.talent.userForms[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
          var userForm = _step3.value;

          if (this.isAdministrative && userForm.type === 'ADMINISTRATIVE' || !this.isAdministrative && userForm.type !== 'ADMINISTRATIVE') {
            forms.push(userForm);
          }
        }
      } catch (err) {
        _didIteratorError3 = true;
        _iteratorError3 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion3 && _iterator3.return != null) {
            _iterator3.return();
          }
        } finally {
          if (_didIteratorError3) {
            throw _iteratorError3;
          }
        }
      }
    }

    var _iteratorNormalCompletion4 = true;
    var _didIteratorError4 = false;
    var _iteratorError4 = undefined;

    try {
      for (var _iterator4 = this.talent.selectedTemplate.periods[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
        var period = _step4.value;

        if (period.tasks) {
          var _iteratorNormalCompletion6 = true;
          var _didIteratorError6 = false;
          var _iteratorError6 = undefined;

          try {
            for (var _iterator6 = period.tasks[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
              var task = _step6.value;

              if (task.form) {
                var found = false;
                var _iteratorNormalCompletion7 = true;
                var _didIteratorError7 = false;
                var _iteratorError7 = undefined;

                try {
                  for (var _iterator7 = forms[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                    var _form = _step7.value;

                    if (_form.id == task.form.id) {
                      Ember.set(_form, 'relatedTask', {
                        id: task.id,
                        assign: task.assign
                      });
                      found = true;
                      break;
                    }
                  }
                } catch (err) {
                  _didIteratorError7 = true;
                  _iteratorError7 = err;
                } finally {
                  try {
                    if (!_iteratorNormalCompletion7 && _iterator7.return != null) {
                      _iterator7.return();
                    }
                  } finally {
                    if (_didIteratorError7) {
                      throw _iteratorError7;
                    }
                  }
                }

                if (!found && (this.isAdministrative && task.form.type === 'ADMINISTRATIVE' || !this.isAdministrative && task.form.type !== 'ADMINISTRATIVE')) {
                  Ember.set(task.form, 'relatedTask', {
                    id: task.id,
                    assign: task.assign
                  });
                  forms.push(task.form);
                }
              }
            }
          } catch (err) {
            _didIteratorError6 = true;
            _iteratorError6 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion6 && _iterator6.return != null) {
                _iterator6.return();
              }
            } finally {
              if (_didIteratorError6) {
                throw _iteratorError6;
              }
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError4 = true;
      _iteratorError4 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion4 && _iterator4.return != null) {
          _iterator4.return();
        }
      } finally {
        if (_didIteratorError4) {
          throw _iteratorError4;
        }
      }
    }

    if (this.talent.otherReadableForms) {
      forms = forms.concat(this.talent.otherReadableForms);
    }

    forms.sort(function (f1, f2) {
      var f1Title = f1.title ? f1.title.toUpperCase() : '';
      var f2Title = f2.title ? f2.title.toUpperCase() : '';

      if (f1Title < f2Title) {
        return -1;
      }

      if (f1Title < f2Title) {
        return 1;
      }

      return 0;
    }); //start form from dashboard tasks or from my tasks

    if (this.formId) {
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = forms[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var form = _step5.value;

          if (form.id == this.formId && form.completed != true) {
            this.eventBus.off('FORCE_OPEN_FORM', this, 'forceOpenForm');
            this.send('startForm', form);
            break;
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return != null) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }
    }

    if (this.talent.userSignContents && this.isAdministrative) {
      this.talent.userSignContents.forEach(function (signContent) {
        if (signContent.completed && signContent.completedUrl) {
          _this2.signedDocuments.push({
            title: signContent.title,
            url: signContent.completedUrl
          });
        }
      });
    }

    return forms;
  })), _defineProperty(_EmberComponent$exte, "countValideDocs", function countValideDocs() {
    var count = 0;

    if (this.formToValidate) {
      this.formToValidate.fields.forEach(function (element) {
        if (element.isValid) {
          count++;
        }
      });
      Ember.set(this, 'validDocsNb', count);
    }

    return;
  }), _defineProperty(_EmberComponent$exte, "actions", {
    openFormAction: function openFormAction(formId) {
      if (this.showActions == formId) {
        Ember.set(this, 'showActions', undefined);
      } else {
        Ember.set(this, 'showActions', formId);
      }
    },
    initValidations: function initValidations(form) {
      var _this3 = this;

      Ember.set(this, 'canCloseDocumentViewerWithoutSaving', true);
      Ember.set(this, 'showActions', false);
      var path = _environment.default.APP.API_HOST + '/forms/' + form.id + '/response/' + this.talent.id;
      Ember.set(this, 'isLoading', true);
      this.ajax.requestPromise(path, 'GET').then(function (data) {
        data.fields = data.fields.filter(function (field) {
          return field.type == 'file';
        });
        data.fields.forEach(function (element) {
          if (element.value && element.value.includes('.pdf')) {
            Ember.set(element, 'isPdf', true); //set(element,'value', element.value );
          } else {
            Ember.set(element, 'isPdf', false);
          }
        });
        Ember.set(_this3, 'isLoading', false);
        Ember.set(_this3, 'formToValidate', data);

        _this3.send('selectField', data.fields[0]);

        _this3.countValideDocs();

        if (_this3.showValidationPopUp == form) {
          Ember.set(_this3, 'showValidationPopUp', undefined);
        } else {
          Ember.set(_this3, 'showValidationPopUp', form);
        }
      }).catch(function (error) {
        console.log(error);
        Ember.set(_this3, 'isLoading', false);

        _this3.notify.error(_this3.intl.t('form.validation.error_message'), {
          closeAfter: 5000
        });
      });
    },
    showFormResponse: function showFormResponse(form) {
      var comment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!form.canViewResponses) return;
      Ember.set(this, 'form', form);
      Ember.set(this, 'canComment', comment);
      Ember.set(this, 'showResponseModal', true);
      Ember.set(this, 'showActions', false);
    },
    deleteResponses: function deleteResponses(form) {
      Ember.set(this, "showRemoveConfirm", form);
      Ember.set(this, 'showActions', false);
    },
    removeConfirmation: function removeConfirmation(response) {
      var _this4 = this;

      if (response) {
        if (this.showRemoveConfirm) {
          var path = _environment.default.APP.API_HOST + '/forms/' + this.showRemoveConfirm.id + '/deleteresponse/' + this.talent.id;
          this.ajax.requestPromise(path, 'DELETE').then(function () {
            Ember.set(_this4.showRemoveConfirm, 'deleted', true);
          });
        }
      }
    },
    onClickOverlay: function onClickOverlay() {
      Ember.set(this, 'showPreviewDialog', false);
    },
    downloadAll: function downloadAll() {
      var _this5 = this;

      var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
      this.ajax.requestPromise(pathOTP).then(function (otp) {
        var path = _environment.default.APP.API_HOST + '/forms/download/talent/' + _this5.talent.id + "?otp=" + otp + "&adminForms=" + (_this5.isAdministrative ? 'true' : "false") + "&templateId=" + _this5.talent.selectedTemplate.id; //creating an invisible element

        var element = document.createElement('a');
        element.setAttribute('href', path);
        element.setAttribute("target", "_blank");
        document.body.appendChild(element); //onClick property

        element.click();
        document.body.removeChild(element);
      });
    },
    startForm: function startForm(form) {
      Ember.set(this, 'form', form);
      Ember.set(this, 'showFormModal', true);
    },
    onResponseSent: function onResponseSent() {
      if (this.onResponseSent) this.onResponseSent();
      var _iteratorNormalCompletion8 = true;
      var _didIteratorError8 = false;
      var _iteratorError8 = undefined;

      try {
        for (var _iterator8 = this.forms[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
          var form = _step8.value;

          if (form.id == this.form.id) {
            Ember.set(form, 'completed', true);
            break;
          }
        }
      } catch (err) {
        _didIteratorError8 = true;
        _iteratorError8 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion8 && _iterator8.return != null) {
            _iterator8.return();
          }
        } finally {
          if (_didIteratorError8) {
            throw _iteratorError8;
          }
        }
      }
    },
    selectField: function selectField(field) {
      var _this6 = this;

      Ember.set(this, 'showDoc', false);

      if (field && this.selectedItem) {
        Ember.set(this.selectedItem, 'showComment', false);
        Ember.set(this, 'selectedItem', field);

        if (field && field.rejectReason) {
          Ember.set(this.selectedItem, 'showComment', true);
        }
      }

      setTimeout(function () {
        Ember.set(_this6, 'showDoc', true);
      }, 100);
    },
    validateDoc: function validateDoc(field) {
      if (!field.value) return;
      Ember.set(this, 'canCloseDocumentViewerWithoutSaving', false);
      Ember.set(this, 'validDocsNb', 0);
      this.formToValidate.fields.forEach(function (element) {
        if (element.id == field.id) {
          Ember.set(element, 'isValid', true);
          Ember.set(element, 'rejectReason', null);
          Ember.set(element, 'showComment', null);
        }
      });

      if (this.selectedItem.id == field.id) {
        Ember.set(this.selectedItem, 'isValid', true);
      }
    },
    initInValidateDoc: function initInValidateDoc(field) {
      if (this.selectedItem.id != field.id) {
        this.send('selectField', field);
      }

      Ember.set(this.selectedItem, 'showComment', !field.showComment);
    },
    inValidateDoc: function inValidateDoc(field) {
      if (!field.rejectReason) {
        this.formToValidate.fields.forEach(function (element) {
          if (element.id == field.id) {
            Ember.set(element, 'isValid', null);
          }
        });
        return;
      }

      Ember.set(this, 'canCloseDocumentViewerWithoutSaving', false);
      Ember.set(this, 'validDocsNb', 0);
      this.formToValidate.fields.forEach(function (element) {
        if (element.id == field.id) {
          Ember.set(element, 'isValid', false);
          Ember.set(element, 'rejectReason', field.rejectReason);
        }
      });

      if (this.selectedItem.id == field.id) {
        Ember.set(this.selectedItem, 'isValid', false);
        Ember.set(this.selectedItem, 'rejectReason', field.rejectReason);
      }
    },
    cancelValidation: function cancelValidation(field) {
      Ember.set(this, 'canCloseDocumentViewerWithoutSaving', false);
      Ember.set(this, 'validDocsNb', 0);
      this.formToValidate.fields.forEach(function (element) {
        if (element.id == field.id) {
          Ember.set(element, 'isValid', null);
          Ember.set(element, 'rejectReason', null);
          Ember.set(element, 'showComment', null);
        }
      });

      if (this.selectedItem.id == field.id) {
        Ember.set(this.selectedItem, 'isValid', null);
        Ember.set(this.selectedItem, 'rejectReason', null);
        Ember.set(this.selectedItem, 'showComment', null);
      }
    },
    saveFormDocumentValidations: function saveFormDocumentValidations() {
      var _this7 = this;

      var allValid = true;
      this.formToValidate.fields.forEach(function (element) {
        if (element.showComment && !element.rejectReason) {
          allValid = false;
        }
      });

      if (!allValid) {
        this.notify.error(this.intl.t('form.validation.complete_all_reject_reasons'), {
          closeAfter: 10000
        });
        return;
      }

      Ember.set(this.formToValidate, 'talentId', this.talent.id);
      var path = _environment.default.APP.API_HOST + '/forms/' + this.formToValidate.id + '/talent/' + this.talent.id + '/validate';
      this.ajax.requestWithBodyPromise(path, 'PATCH', 'application/json', JSON.stringify(this.formToValidate)).then(function () {
        _this7.countValideDocs();

        _this7.forms.forEach(function (element) {
          if (element.id == _this7.formToValidate.id) {
            Ember.set(element, 'countValidatedDocuments', _this7.formToValidate.fields.filterBy('isValid', true).length);
          }
        });

        Ember.set(_this7, 'showValidationPopUp', false);

        _this7.notify.info(_this7.intl.t('form.validation.success_message'), {
          closeAfter: 5000
        });
      }).catch(function (e) {
        _this7.notify.error(_this7.intl.t('form.validation.error_message'), {
          closeAfter: 10000
        });
      });
    },
    validateAllFormDocuments: function validateAllFormDocuments() {
      var _this8 = this;

      var path = _environment.default.APP.API_HOST + '/forms/' + this.formToValidate.id + '/talent/' + this.talent.id + '/validate-all';
      this.ajax.requestPromise(path, 'PATCH').then(function (result) {
        _this8.forms.forEach(function (element) {
          if (element.id == result.id) {
            Ember.set(element, 'countValidatedDocuments', result.countValidatedDocuments);
          }
        });

        _this8.formToValidate.fields.forEach(function (element) {
          if (element.value) {
            Ember.set(element, 'isValid', true);
            Ember.set(element, 'rejectReason', null);
            Ember.set(element, 'showComment', null);
          }
        });

        _this8.notify.info(_this8.intl.t('form.validation.success_message'), {
          closeAfter: 5000
        });
      }).catch(function (e) {
        _this8.notify.error(_this8.intl.t('form.validation.error_message'), {
          closeAfter: 10000
        });
      });
    },
    sendDocsToSftpServer: function sendDocsToSftpServer() {
      var _this9 = this;

      if (!this.allowSftpStorage) return;
      var path = _environment.default.APP.API_HOST + '/sftps/send/form/' + this.formToValidate.id + '/talent/' + this.talent.id;
      Ember.set(this, 'isLoadingSftp', true);
      this.ajax.requestPromise(path, 'POST').then(function () {
        Ember.set(_this9, 'isLoadingSftp', false);

        _this9.notify.info(_this9.intl.t('form.validation.send_to_server_success_message'), {
          closeAfter: 5000
        });
      }).catch(function (e) {
        Ember.set(_this9, 'isLoadingSftp', false);

        _this9.notify.error(_this9.intl.t('form.validation.error_message'), {
          closeAfter: 10000
        });
      });
    },
    cancelDocumentValidation: function cancelDocumentValidation(response) {
      if (response) {
        Ember.set(this, 'showValidationPopUp', false);
      }
    },
    initCloseDocumentViewer: function initCloseDocumentViewer() {
      if (this.canCloseDocumentViewerWithoutSaving) {
        Ember.set(this, 'showValidationPopUp', false);
      } else {
        Ember.set(this, 'showCancelPopUp', true);
      }
    },
    getSignedDocument: function getSignedDocument(url) {
      var path = _environment.default.APP.API_HOST + "/signcontents/signedDocument?url=" + url;
      this.ajax.requestPromise(path, "GET", null, {
        responseType: 'text'
      }).then(function (data) {
        var binaryString = atob(data);
        var binaryData = new Uint8Array(binaryString.length);

        for (var i = 0; i < binaryString.length; i++) {
          binaryData[i] = binaryString.charCodeAt(i);
        }

        var blob = new Blob([binaryData], {
          type: 'application/pdf'
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var pattern = /\/documents\/([^/]+)\/download/;
        link.download = pattern.exec(url) ? pattern.exec(url)[1] + ".pdf" : "signature.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});