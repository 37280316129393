define("teelt-frontend/components/notification/send-notification", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['send-notification'],
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    selectedLang: "en",
    notificationMarker: Ember.inject.service(),
    activeSlack: false,
    notifPath: _environment.default.APP.API_HOST + "/notifications?type=CUSTOM",
    init: function init() {
      this._super.apply(this, arguments);

      var systemNotifs = [{
        key: 'INVIT',
        label: this.intl.t('send_notification.system.INVIT')
      }];

      if (this.session.data.authenticated.user.lang) {
        Ember.set(this, 'selectedLang', this.session.data.authenticated.user.lang);
      }

      if (this.session.data.authenticated.user.company.activeSlack) {
        Ember.set(this, 'activeSlack', true);
      }

      Ember.set(this, "systemNotifs", systemNotifs);
    },
    setEmailFromContents: function setEmailFromContents() {
      var emailContent = this.getEmailContentFromContents();

      if (emailContent) {
        Ember.set(this, "email", emailContent);
      } else {
        var _emailContent = {
          contentType: "EMAIL",
          subject: "",
          body: "",
          disabled: true,
          replyTo: "",
          lang: this.selectedLang,
          officeId: null,
          tagId: null,
          cc: "",
          sourceCode: "",
          creationCode: ""
        };
        this.notification.contents.push(_emailContent);
        Ember.set(this, "email", _emailContent);
      }
    },
    getEmailContentFromContents: function getEmailContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];

        if (content.contentType === "EMAIL" && content.lang === this.selectedLang) {
          return this.notification.contents[i];
        }
      }
    },
    setSmsContentFromContents: function setSmsContentFromContents() {
      var smsContent = this.getSmsContentFromContents();

      if (smsContent) {
        Ember.set(this, "sms", smsContent);
      } else {
        var _smsContent = {
          contentType: "SMS",
          body: "",
          disabled: true,
          lang: this.selectedLang
        };
        this.notification.contents.push(_smsContent);
        Ember.set(this, "sms", _smsContent);
      }
    },
    getSmsContentFromContents: function getSmsContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SMS" && content.lang === this.selectedLang) return this.notification.contents[i];
      }
    },
    setSlackContentFromContents: function setSlackContentFromContents() {
      var slackContent = this.getSlackContentFromContents();

      if (slackContent) {
        Ember.set(this, "slack", slackContent);
      } else {
        var _slackContent = {
          contentType: "SLACK",
          body: "",
          disabled: true,
          lang: this.selectedLang
        };
        this.notification.contents.push(_slackContent);
        Ember.set(this, "slack", _slackContent);
      }
    },
    getSlackContentFromContents: function getSlackContentFromContents() {
      if (!this.notification.contents) return;

      for (var i = 0; i < this.notification.contents.length; i++) {
        var content = this.notification.contents[i];
        if (content.contentType === "SLACK" && content.lang === this.selectedLang) return this.notification.contents[i];
      }
    },
    getNewAccountNotificationType: function getNewAccountNotificationType() {
      var talentRoles = this.talent.roles;

      if (talentRoles && talentRoles.length) {
        if (talentRoles.includes('ROLE_RH')) return 'NEW_ACCOUNT_RH';
        if (talentRoles.includes('ROLE_MRH')) return 'NEW_ACCOUNT_MRH';
        if (talentRoles.includes('ROLE_CUSTOM_OFFICE_MANAGER')) return 'NEW_ACCOUNT_OFFICE_MANAGER';
        if (talentRoles.includes('ROLE_ADMINISTRATIVE')) return 'NEW_ACCOUNT_ADMINISTRATIVE';
        if (talentRoles.includes('ROLE_MANAGER')) return 'NEW_ACCOUNT_MANAGER';
        if (talentRoles.includes('ROLE_CONSULT')) return 'NEW_ACCOUNT_CONSULT';
        return 'NEW_ACCOUNT_TALENT';
      }
    },
    actions: {
      toggleShowNotification: function toggleShowNotification(value) {
        if (value == "showEmail") {
          if (this.showEmail) {
            Ember.set(this, 'showEmail', false);
            return;
          }

          Ember.set(this, 'showEmail', true);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', false);
        } else if (value == "showSms") {
          if (this.showSms) {
            Ember.set(this, 'showSms', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSlack', false);
          Ember.set(this, 'showSms', true);
        } else {
          if (this.showSlack) {
            Ember.set(this, 'showSlack', false);
            return;
          }

          Ember.set(this, 'showEmail', false);
          Ember.set(this, 'showSms', false);
          Ember.set(this, 'showSlack', true);
        }
      },
      onNotifChanged: function onNotifChanged(notif) {
        var _this = this;

        if (!notif) return;

        if (notif.key == 0) {
          Ember.set(this, 'selectedNotification', undefined);
          Ember.set(this, 'notification', undefined);
          Ember.set(this, 'email', undefined);
          Ember.set(this, 'sms', undefined);
        } else {
          Ember.set(this, 'selectedNotification', notif);

          if (notif.key !== 'INVIT') {
            var path = _environment.default.APP.API_HOST + "/notifications/" + notif.key;
            this.ajax.requestWithBodyPromise(path, 'GET').then(function (result) {
              Ember.set(_this, 'notification', result);

              _this.setEmailFromContents();

              _this.setSmsContentFromContents();

              _this.setSlackContentFromContents();

              Ember.set(_this, 'templateMarkers', _this.notificationMarker.getMarkers(result));
            }).catch(function () {
              Ember.set(_this, 'notification', undefined);
            });
          } else {
            var _path = _environment.default.APP.API_HOST + '/notifications/systems';

            var notifType = this.getNewAccountNotificationType();
            this.ajax.requestPromise(_path).then(function (result) {
              result.forEach(function (notif) {
                if (notif.type === notifType) {
                  Ember.set(_this, 'notification', notif);
                  Ember.set(_this, 'templateMarkers', _this.notificationMarker.getMarkers(notif));
                }
              });
            }).catch(function () {
              Ember.set(_this, 'notification', undefined);
            });
          }
        }
      },
      send: function send() {
        var _this2 = this;

        Ember.set(this, 'blocked', true);
        var path = _environment.default.APP.API_HOST + "/notifications/";

        if (this.selectedNotification.key == 'INVIT') {
          path += "systems/" + this.selectedNotification.key + "/users/" + this.talent.id;
        } else {
          path += this.selectedNotification.key + "/users/" + this.talent.id;
        }

        if (this.notification && this.notification.contents) {
          this.notification.contents.forEach(function (content) {
            if (content.contentType === "EMAIL") {
              if (content.sourceCode) {
                var minifySourceCode = content.sourceCode.replace(/\n|\t/g, ' ').replace(/> *</g, '><');
                Ember.set(content, "sourceCode", minifySourceCode);
                Ember.set(content, "body", minifySourceCode);
              } else {
                if (content.creationCode) {
                  Ember.set(content, "body", content.creationCode);
                }
              }
            }
          });
        }

        this.ajax.requestWithBodyPromise(path, 'POST', 'application/json', JSON.stringify(this.notification)).then(function (result) {
          Ember.set(_this2, 'blocked', false);

          if (result) {
            _this2.notify.info(_this2.intl.t('talent.messages.notification_sent'), {
              closeAfter: 5000
            });

            Ember.set(_this2, 'show', false);
          } else {
            _this2.notify.error(_this2.intl.t('talent.errors.notification_failed'), {
              closeAfter: 5000
            });
          }
        }).catch(function () {
          Ember.set(_this2, 'blocked', false);

          _this2.notify.error(_this2.intl.t('talent.errors.notification_failed'), {
            closeAfter: 5000
          });
        });
      }
    }
  });

  _exports.default = _default;
});