define("teelt-frontend/routes/board/setting/api-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      generateToken: function generateToken() {
        var _this = this;

        this.userService.generateApiToken().then(function (data) {
          Ember.set(_this.currentModel, 'token', data);
          Ember.set(_this.currentModel, 'showTokenModal', true);
        });
      },
      revealToken: function revealToken() {
        var pw = document.getElementById('tokenInput');

        if (pw.type == 'password') {
          pw.type = 'text';
        } else {
          pw.type = 'password';
        }
      }
    }
  });

  _exports.default = _default;
});