define("teelt-frontend/routes/board/setting/sftp-config", ["exports", "teelt-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      exportDocumentValidationLogs: function exportDocumentValidationLogs() {
        var exportFormat = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'csv';
        Ember.set(this.currentModel, 'showExportDocumentValidationLogsModal', false);
        var pathOTP = _environment.default.APP.API_HOST + "/users/otp";
        this.ajax.requestPromise(pathOTP).then(function (otp) {
          setTimeout(function () {
            var path = _environment.default.APP.API_HOST + "/activities/users/export_document_validation/" + exportFormat + "?otp=" + otp;
            var element = document.createElement('a');
            element.setAttribute('href', path);
            element.setAttribute("target", "_blank");
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
          }, 100);
        });
      }
    }
  });

  _exports.default = _default;
});